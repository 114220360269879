import React, { Component } from 'react';
import { useParams, useNavigate,Link  } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl,tapURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyEditor from '../Membership/MyEditor';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
	
	return (
	  <WrappedComponent
	  {...props}
	  params={params}
	  navigate ={navigate}
	  // etc...
	  />
	);
	};

class Edit extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const tabId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
            tabId: tabId,
			tab_name:'',
			description:'',
			title:'',
			content:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedtype : '',
			selectedtypeValue : '',
			editid:0,
			image_preview:'',
			image_name:'',
			image:'',
			tabFolder:'',
			old_image:'',
			fileExtension:'',
			showCategoryDropdown:false,
			category_id:'',
			categorynamelist: [],
			selectedCategory:'',
			button_name:'',
			short_description:'',
			tabgalleries: '',
			selectid:'',
			imagepath:'',
			selected_image:'',
			tab_image:'',
			tabdescriptions_name:'',
			agreedescription:'',
			
		};
		this.onEditorChange = this.onEditorChange.bind( this );
		this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
		this.removeImagebyindex = this.removeImagebyindex.bind(this);
		// this.props.getCategoryDetailList(tabId);
		var qs = require('qs');
		var catid = this.state.tabId;
		var postObject = {
			id : this.state.tabId,
			admin_id : localStorage.getItem("admin_id"),
		};
		axios.post(apiUrl+'tabmanagement/listdetail',qs.stringify(postObject))
		.then(res => {
			this.setState({tab_name:res.data.tablist.name, short_description:res.data.tablist.short_description,agreedescription:res.data.tablist.form_agreedescription,tabdescriptions_name:res.data.tablist.tabdescriptions,description: res.data.tablist.description,button_name: res.data.tablist.button_name,category_id:res.data.category,description:res.data.tablist.description,title:res.data.tablist.title,content:res.data.tablist.content});
			this.setState({ selectedCategory : res.data.categoryDetails})
			this.setState({ tabgalleries: res.data.tabimageslist }, () => {
				console.log(this.state, 'productedit');
				// Render your galleries here using this.state.tabgalleries
			});
			if(res.data.tablist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(res.data.tablist.content_type !=''){
				this.setState({selectedtype:{value: res.data.tablist.content_type, label: lang.Capitalize(res.data.tablist.content_type)}});
			}
			
			this.setState({selectedtypeValue:res.data.tablist.content_type,editid:1,image_preview:res.data.tablist.image,tabFolder:res.data.tabfolder,old_image:res.data.tablist.image})
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);

    }
	uploadMultipleFiles = (e) =>{
		this.fileObj = [];
	
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				//this.fileArray.push(this.fileObj[0][i]
				this.imageArray.push(this.fileObj[0][i])
	
			}
			
			this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
		}
		removeImagebyindex = (indexvalue) => {
		   
			var list = [...this.imageArray];
			this.imageArray = [];
			this.fileArray = [];
			list.splice(indexvalue, 1);
		   
			for (let i = 0; i < list.length; i++) {
			  this.imageArray.push(list[i]);
			  this.fileArray.push(URL.createObjectURL(list[i]));
			}
		  
			  this.setState({ tab_image: this.imageArray })	
	  }
    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	onEditorChange( evt ) {
		console.log(evt, 'CKE')
		setTimeout(function () {
		this.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'tab_trending'){
      	this.setState({tab_trending_checked: !this.state.tab_trending_checked});
      }  		
    }

	componentDidMount() {
		document.title = PageTitle('Tab Edit');
		var admin_id = localStorage.getItem('admin_id');
			
    }

	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file,
			fileExtension:extension,editid:0
		  });
		};
		reader.readAsDataURL(file);
	}
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					tab_name: formPayload.tab_name,
					tab_description: formPayload.description,
					short_description: formPayload.short_description,
					
					button_name: formPayload.button_name,
					content_type: formPayload.selectedtypeValue,
					// content: formPayload.content,
					status:  status,
					editid:  this.state.editid,
					old_image:  this.state.old_image,
					image: this.state.editid===0 ? formPayload.image:'',
					id: formPayload.tabId,
					tabdescriptions_name: formPayload.tabdescriptions_name,
					agreedescription: formPayload.agreedescription,
				};
				if (formPayload.selectedtypeValue !== 'product') {
					postObject.category = null;
				} else {
					// If content_type is 'product', assign the category value
					postObject.category = formPayload.categoryvalue;
				}
				
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		
				var filesdata = this.state.tab_image;
						var galleryimg = this.state.galleryimg;
						if(galleryimg==2){
						for (var i in filesdata) {
							formData.append('multiple_images[]',filesdata[i])
						}
					}         
				axios.post(apiUrl+"tabmanagement/edit",formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).
				
				then(res => {
					if(res.data.status === "success"){	
						this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/tabmanagement');
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		console.log(this.state.image,"selectedtypeValue")
		const {tab_name,title, tabdescriptions_name,description,selectedtypeValue,image ,short_description ,button_name} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		  if (!tab_name) {
			formIsValid = false;
			$('.errortab_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(tab_name){
			if (/^[a-zA-Z\s]*$/.test(tab_name)) {
			$('.errortab_name').html('');
			}else{
				formIsValid = false;
				$('.errortab_name').html('Please enter valid tab name');
			}
		}
		
		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the short descrtions</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}
		if (!tabdescriptions_name) {
			formIsValid = false;
			$('.errortabdescrtions_name').html('<span class="errorspan">Please fill the tab descrtions</span>');
		}else if(tabdescriptions_name){
			$('.errortabdescrtions_name').html('');
		}
		
		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		if (!button_name) {
			formIsValid = false;
			$('.errorbutton_name').html('<span class="errorspan">Please fill the button name</span>');
		}else if(button_name){
			$('.errorbutton_name').html('');
		}
		if (!selectedtypeValue) {
			formIsValid = false;
			$('.errorcontenttype').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedtypeValue){
			$('.errorcontenttype').html('');
		}

		if (this.state.editid==0) {
			if (!image) {
				
				formIsValid = false;
				$('.errorfile').html('<span class="errorspan">Please fill the feild</span>');
					}else{
						$('.errorfile').html('');
					}
					if (image) {		
			if (!image.name.match(/\.(jpg|jpeg|png|svg)$/)) {
				
				formIsValid = false;
					 $('.errorfile').html('<span class="errorspan">Please select valid type</span>');
				   
			  }else{
				$('.errorfile').html('');
			  }
			}
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handleClickSelecetCategory = (event) => {
		const newCategoryId = event.target.value;
		console.log(newCategoryId,'new');
		this.setState({ category_id: newCategoryId });
	};
	
	handleChangeCategory = selectedCategory => {

		this.setState({ selectedCategory});
		this.setState({ categoryvalue : selectedCategory.value });
 	}
	 confirmPopup = (id,imagepath,selected_image,type) => {
		this.setState({selectid: id,imagepath:imagepath,selected_image:selected_image, type: type})	
		$('.confirm-action').addClass('show');		
	}
	deleteimage(){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          gallery_id  :this.state.selectid,
          id  :this.state.tabId,
          imagepath  :this.state.imagepath,
          selected_image  :this.state.selected_image,
		  type: this.state.type
        };
        axios.post(apiUrl+"tabmanagement/deleteimage",qs.stringify(postObject)).then(res => {      
          	if(res.data.status === 'success'){
				if(this.state.type == 'product'){
					this.setState({productgalleries:res.data.productgalleries})
				}else{
					this.setState({tryoutgalleries:res.data.tryoutgalleries})
				}
				this.setState({ tabgalleries: res.data.productgalleries }, () => {
					// Render your galleries here using this.state.tabgalleries
				});
				$('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
				setTimeout(function() {
					$('.success_message').html('');   
				}.bind(this),3000);
          	}
        }); 
	}
	Confirmstatus = (confirmstatus) => {
		if(confirmstatus == 'yes'){
		  this.deleteimage();
		  $('.confirm-action').removeClass('show');
		}else{
		  $('.confirm-action').removeClass('show');	
		}  
	}
	handleTypeChange = selectedOption => {
		const selectedTypeValue = selectedOption.value;
		this.setState({ 
			selectedtype: selectedOption,
			selectedtypeValue: selectedOption.value,
			showCategoryDropdown: false,
		  });
		
    };  
  render() {

	const navigate = this.props.navigate;
  	  const {selectedOption,selectedtype ,categorynamelist} = this.state;

		let imageslist = [];

	  let indexlength =  imageslist.length;

	const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
		</li>
	))
	if(this.state.editid==1){
		var loaderimg = tapURL + '/'+this.state.image_preview;
		console.log(loaderimg,'tabimages')
		var preview = <img className="img_class" src={loaderimg} alt="" />;
	}else {
	
		
		
		var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;

	}
	

	
  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="tabmanagement" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="content-wrapper">
				<a onClick={() => navigate(-1)}>Back</a>   
			</div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Tab Name<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="tab_name" onChange={this.handleInputChange} className="form-control" value={this.state.tab_name} />
					<div className="errortab_name"></div>
				</div>
				<div className="form-group">
						<label>Short Description<span class="required" style={{ color: "red" }} >* </span> :</label>
						<MyEditor initialData={this.state.short_description} onEditorChange={this.onEditorChange}></MyEditor>
						<div className="errorshort_description"></div>	
				</div>
				
				
			   <div className="form-row">
									{/* <div className="form-left"> */}
										{this.state.tabgalleries!=''?
										<div className="form-group">
											<div className='gallery-main'>
												<label>Image Galleries</label>
												<div className='gallery-container'>
													{this.state.tabgalleries?.map((item) => (
													<div className='gallery-inner' key={item.rehab_gallery_id}>
														<img width="100px" height="100px" src={tapURL+'/'+'tabimages'+'/'+item.tab_image} alt={`Image ${item.id}`}  />
														 <Link onClick={this.confirmPopup.bind(this, item.id,item.tab_image_path,item.tab_image,'tab')} className="" title="Delete">X</Link>
													</div>
													))}
												</div>
											</div>
										</div>:''}		
									{/* </div> */}
								</div>
			   <div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">
										{/*imageslist*/}
									{gallerylocal}
									</ul>
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					
				</div>
				<div className="form-group">
					<label>Button Text<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="button_name" onChange={this.handleInputChange} className="form-control" value={this.state.button_name}/>
					<div className="errorbutton_name"></div>
				</div>
				 
				
			</div>
                  
			<div className="form-right">
				<div className="form-group">
					<label>Icon (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} >*</span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					{preview}
					</div>

					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorfile"></div>  
				</div>
				<div className="form-group">
									<label>Description<span class="required" style={{ color: "red" }} ></span></label>
									{/* <MyEditor  onEditorChange={this.onEditorChange}></MyEditor> */}
									<CKEditor
										editor={ ClassicEditor }
										data={this.state.description}
										onReady={ editor => {
											// You can store the "editor" and use when it is needed.
											console.log( 'Editor is ready to use!', editor );
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({description: data});
											console.log( data,"data");
										} }
									/>
									<div className="errorshort_description"></div>  
									
								</div>
				
				{/* <div className="form-group">					
					<label>Content:</label>
						<textarea type="text" name='content' onChange={this.handleInputChange} className='form-control' value={this.state.content} />
					<div className="errorcontent"></div>
				</div> */}
				<div className="default-font">
					<div className="form-group">					
					<label>Content Type<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedtype}
                            options={lang.common.contentType_option} 
                            onChange={this.handleTypeChange}
                            />
							<div className="errorcontenttype"></div>
				</div>
				
				{selectedtype.value === 'form' && (
						<div className="form-group">
						<label>Agree Description<span class="required" style={{ color: "red" }} ></span>:</label>
						{/* <MyEditor  onEditorChange={this.onEditorChange}></MyEditor> */}
						<CKEditor
							editor={ ClassicEditor }
							data={this.state.agreedescription}
							onReady={ editor => {
								// You can store the "editor" and use when it is needed.
								console.log( 'Editor is ready to use!', editor );
							} }
							onChange={ ( event, editor ) => {
								const data = editor.getData();
								this.setState({agreedescription: data});
								
							} }
						/>
						<div className="errorshort_description"></div>  
						
					</div>
				)}
				
				</div>

				<div className="form-group">
					<label>Tab Description<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="tabdescriptions_name" onChange={this.handleInputChange} className="form-control" value={this.state.tabdescriptions_name}/>
					<div className="errortabdescrtions_name"></div>
				</div>	
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >* </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
			<div className="confirm-action">
					<p>Are you sure! you want to delete this image?</p>
					<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
					<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
				</div>	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (tabId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,tabId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));