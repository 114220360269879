import React, { Component } from 'react';
import { useParams, useNavigate , Link } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth ,scrollToTopValidate } from "../Helpers/SettingHelper";
import { apiUrl,providerURL ,mediaUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
  import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
	geocodeByPlaceId
  }  from 'react-places-autocomplete';
  import Multiselect from 'multiselect-react-dropdown';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
	
	return (
	  <WrappedComponent
	  {...props}
	  params={params}
	  navigate ={navigate}
	  // etc...
	  />
	);
	};

class Edit extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const tabId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
            tabId: tabId,
			username:'',
			description:'',
			title:'',
			content:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedtype : '',
			selectedtypeValue : '',
			editid:0,
			image_preview:'',
			image_name:'',
			image:'',
			tabFolder:'',
			old_image:'',
			fileExtension:'',
			showCategoryDropdown:false,
			category_id:'',
			categorynamelist: [],
			selectedCategory:'',
			name:'',
			selectedgenderOption:'',
			locationlist: [{id: '', name: 'Select Your Location'}],
			locationselectedList:[],
			specialist_id:'',
			specialist:[],
			selectedSpecialtiesOption:[],
			selectedInsuranceOption:[],
			location:'',
			selectedInsurcanceId:[],
			selectedSpecialtiesId:[],
			zipcode: '',
			reviews:'',
			rating:'',
			latitude:'',
			longitude:'',
			selectedAmenityOption:[],
			selectedAmenityevalue:'',	
			galleries:[],	
			user_image: '',	
			user_image: '',	
			imagepath: '',	
			selected_image: '',	
			galleryimg: 'No',
			city:'',
			state:'',
			country:'',
			degree_name:'',
			isCashOnly: false,
			selectedbookingOption:'',
			selectedBookingvalue:'',
			preferredlocationlist:"",
			selectedlocationOption:"",
			selectedlocationvalue:"",
			selectedpurposeOption:'',
			selectedpurposevalue:'',
			preferredpurposelist:"",
			
		};
					
		
		this.handleSpecialtiesChange = this.handleSpecialtiesChange.bind(this);
		this.handlebookingtypeChange = this.handlebookingtypeChange.bind(this);
		this.handlelocationtypeChange = this.handlelocationtypeChange.bind(this);
		
		this.handlepurposetypeChange = this.handlepurposetypeChange.bind(this);

		// this.props.getCategoryDetailList(tabId);
		var qs = require('qs');
		var catid = this.state.tabId;
		var postObject = {
			provider_id : this.state.tabId,
			admin_id : localStorage.getItem("admin_id"),
		};
		axios.post(apiUrl+'provider/listdetail',qs.stringify(postObject))
		.then(res => {
			
				
			this.setState({zipcode:res.data.providerDetails.zipcode,reviews:res.data.providerDetails.reviews,rating:res.data.providerDetails.rating,image_name:res.data.providerDetails.image,description:res.data.providerDetails.description,username:res.data.providerDetails.username ,name:res.data.providerDetails.name ,email:res.data.providerDetails.email,phoneno:res.data.providerDetails.phone,location:res.data.providerDetails.location,latitude:res.data.providerDetails.latitude,longitude:res.data.providerDetails.longitude});
			
			const formdata = res.data.specialties;
			const insurance = res.data.insurance;
			const amenities = res.data.amenities;
			const locations = res.data.locations;
			this.setState({selectedlocationOption:locations});
			const purposenames = res.data.purposenames;
			this.setState({selectedpurposeOption:purposenames});
			if(res.data.providerDetails.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(res.data.providerDetails.gender === 'female'){
				this.setState({selectedgenderOption:{value: 'female', label: 'Female'}});
			}else if(res.data.providerDetails.gender === 'male'){
				this.setState({selectedgenderOption:{value: 'male', label: 'Male'}});
			} else {
				this.setState({selectedgenderOption:{value: 'transgender', label: 'Transgender'}});
			}
					
				if (insurance[0] && insurance[0].provider_charge_type === 'Cash only') {
				this.setState({ isCashOnly: true });
			  } else {
				this.setState({ isCashOnly: false });
			  }
					this.setState({selectedSpecialtiesOption:formdata });
					this.setState({selectedInsuranceOption:insurance });
					this.setState({selectedcategoryvalue:formdata.amenityids});
			        this.setState({selectedAmenityOption:amenities});
					this.setState({galleries:res.data.galleries});
					this.setState({degree_name:res.data.providerDetails.degree_name});
					
					
					console.log(res.data.providerDetails.bookingtypeids,'this is bookingtypeids')
					
						const bookingtypeids = res.data.providerDetails.bookingtypeids;

						if (bookingtypeids) {
							// Split the string into an array
							const bookingtypeArray = bookingtypeids.split(',');
						  
							// Map over the array to create the options
							const selectedBookingOptions = bookingtypeArray.map(booking => {
							  return { label: booking.trim(), value: booking.trim() };
							});
						  
							this.setState({
							  selectedbookingOption: selectedBookingOptions,
							  selectedBookingvalue: bookingtypeArray
							});
						  } else {
							// Handle the case when bookingtypeids is null or undefined
							this.setState({
							  selectedbookingOption: [],
							  selectedBookingvalue: []
							});
						  }
					// for (var i = 0; i < insurance.length; i++) {
					// 	// Access the 'value' property of each object and push it to the extractedValues array
					// 	extractedValues.push(specialtiesArray[i].value);
					// }
					// console.log(extractedValues,'excpected');
					// this.setState({selectedInsurcanceId:insurance.value });
					// this.setState({selectedSpecialtiesId:formdata.value });
			
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);

    }
	
    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'tab_trending'){
      	this.setState({tab_trending_checked: !this.state.tab_trending_checked});
      }  		
    }
	handlebookingtypeChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});

			
		this.setState({selectedbookingOption:selCategory});
		this.setState({  selectedBookingvalue : selCategoryValue});
 	};
	 handlelocationtypeChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});

			
		this.setState({selectedlocationOption:selCategory});
		this.setState({  selectedlocationvalue : selCategoryValue});
 	};
	 handlepurposetypeChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});

			
		this.setState({selectedpurposeOption:selCategory});
		this.setState({  selectedpurposevalue : selCategoryValue});
 	};
	 
	 
	// handleSpecialtiesChange = (selOption) => {
       
	// 	let selCategory = [];
	// 	let selCategoryValue = [];
	// 	selOption.map(item => {
	// 		selCategoryValue.push(item.value);
	// 		selCategory.push({ label: item.label, value: item.value });
	// 	});
		
	// 	this.setState({selectedSpecialtiesOption:selCategory});
	// 	this.setState({  selectedSpecialtiesvalue : selCategoryValue});
 	// };
	 handleSpecialtiesChange = (selOption) => {
		if (selOption) {
		  const selCategory = { label: selOption.label, value: selOption.value };
		  const selCategoryValue = selOption.value;
		  
		  this.setState({
			selectedSpecialtiesOption: selCategory,
			selectedSpecialtiesvalue: selCategoryValue
		  });
		} else {
		  this.setState({
			selectedSpecialtiesOption: null,
			selectedSpecialtiesvalue: null
		  });
		}
	  };
	componentDidMount() {
		document.title = PageTitle('Tab Edit');
		var admin_id = localStorage.getItem('admin_id');
		var qs = require('qs');
		var postObject = {
			admin_id : localStorage.getItem("admin_id"),
		};
		axios.post(apiUrl+'provider/listdetailsinsurance',qs.stringify(postObject))
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						specialist: [{value: '', label: 'Select Your Specialist'}].concat(res.data.specialtieslist)
					})
					this.setState({
						insuranceList: [{value: '', label: 'Select Your Specialist'}].concat(res.data.insurancelist)
					})
				}else{
				
				}
			});

			this.listloaddata();
			this.listloadlocations();
			
			// });
    }

	
	listloadlocations=()=>{
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'provider/locationslist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						preferredlocationlist:res.data.locationslist,
						preferredpurposelist:res.data.purposelist
					});
				
				}else{
				}
			});
	}
	listloaddata=()=>{
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'provider/getamenitynamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						amenitynamelist:res.data.amenitynamelist
					});
				
				}else{
				}
			});
	}

	listproviderdata=()=>{
		var qs = require('qs');
		var catid = this.state.tabId;
		var postObject = {
			provider_id : this.state.tabId,
			admin_id : localStorage.getItem("admin_id"),
		};
		axios.post(apiUrl+'provider/listdetail',qs.stringify(postObject))
		.then(res => {
			
				
			this.setState({zipcode:res.data.providerDetails.zipcode,reviews:res.data.providerDetails.reviews,rating:res.data.providerDetails.rating,image_name:res.data.providerDetails.image,description:res.data.providerDetails.description,username:res.data.providerDetails.username ,name:res.data.providerDetails.name ,email:res.data.providerDetails.email,phoneno:res.data.providerDetails.phone,location:res.data.providerDetails.location,latitude:res.data.providerDetails.latitude,longitude:res.data.providerDetails.longitude});
			
			const formdata = res.data.specialties;
			const insurance = res.data.insurance;
			const amenities = res.data.amenities;

			const locations = res.data.locations;
			
			if(res.data.providerDetails.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(res.data.providerDetails.gender === 'female'){
				this.setState({selectedgenderOption:{value: 'female', label: 'Female'}});
			}else if(res.data.providerDetails.gender === 'male'){
				this.setState({selectedgenderOption:{value: 'male', label: 'Male'}});
			} else {
				this.setState({selectedgenderOption:{value: 'transgender', label: 'Transgender'}});
			}

			if (insurance && insurance[0] && insurance[0].provider_charge_type === 'Cash only') {
				this.setState({ isCashOnly: true });
			  } else {
				this.setState({ isCashOnly: false });
			  }
			
					
					this.setState({selectedSpecialtiesOption:formdata });
					this.setState({selectedInsuranceOption:insurance });
					this.setState({selectedcategoryvalue:formdata.amenityids});
			        this.setState({selectedAmenityOption:amenities});
					this.setState({selectedlocationOption:locations});
					this.setState({galleries:res.data.galleries});

					const purposenames = res.data.purposenames;
					this.setState({selectedpurposeOption:purposenames});
					
					this.setState({editid:1});
					
					// for (var i = 0; i < insurance.length; i++) {
					// 	// Access the 'value' property of each object and push it to the extractedValues array
					// 	extractedValues.push(specialtiesArray[i].value);
					// }
					// console.log(extractedValues,'excpected');
					// this.setState({selectedInsurcanceId:insurance.value });
					// this.setState({selectedSpecialtiesId:formdata.value });
			
		}); 
		
	}
	onChangeHandler=event=>{
		let reader = new FileReader();
		console.log(reader,'image')
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file,
			fileExtension:extension,editid:0
		  });
		};
		
		reader.readAsDataURL(file);
	}
	handleCashOnlyChange = (event) => {
		this.setState({ isCashOnly: event.target.checked });
	  };
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				var gender = '';
				
				
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					username: formPayload.username,
					name : formPayload.name,
					description: formPayload.description,
					location: formPayload.location,
					phone: formPayload.phoneno,
					email: formPayload.email,
					gender:formPayload.selectedgendervalue ? formPayload.selectedgendervalue : formPayload.selectedgenderOption.value,
					status:  formPayload.selectedvalue ? formPayload.selectedvalue : formPayload.selectedOption.value,
					editid:  this.state.editid,
					image: formPayload.image?formPayload.image:formPayload.image_name,
					id: formPayload.tabId,
					licence: formPayload.image?formPayload.image:'',
					id: formPayload.tabId,
					insurance: formPayload.selectedInsurancevalue ? formPayload.selectedInsurancevalue :'',
					specialties: formPayload.selectedSpecialtiesvalue ? formPayload.selectedSpecialtiesvalue :'',
					reviews: formPayload.reviews,
					rating : formPayload.rating,
					latitude: formPayload.latitude,
					longitude: formPayload.longitude,
					zipcode : formPayload.zipcode,
					amenityids:formPayload.selectedAmenityevalue,
					city:formPayload.city,
					state:formPayload.state,
					country:formPayload.country,
					degree_name:formPayload.degree_name,
					charge_type: this.state.isCashOnly ? 'Cash only' : 'Insurance',
					bookingtype:formPayload.selectedBookingvalue,
					locationtype:formPayload.selectedlocationvalue,
					purposetype:formPayload.selectedpurposevalue,

					
				};
						
		
				if (formPayload.selectedtypeValue !== 'product') {
					postObject.category = null;
				} else {
					// If content_type is 'product', assign the category value
					postObject.category = formPayload.categoryvalue;
				}
				
				let formData = new FormData();
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}		
				
				var galleryimg = this.state.galleryimg;

				var filesdata = this.state.user_image;
				if(galleryimg==2){
					for (var i in filesdata) {
						formData.append('multiple_images[]',filesdata[i])
					}
				}
				    var filesdata;			
					filesdata = this.state.selectedimages;	
					if(galleryimg==1){
						for (var i in filesdata) {
							formData.append('images[]',filesdata[i])
						}
					}

				
				axios.post(apiUrl+"provider/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/provider');
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		
		const {username,name, zipcode ,description,selectedInsuranceOption,image, rating, reviews, phoneno ,selectedAmenityOption} = this.state;
		//let errors = {};
      	let formIsValid = true;
		let errors = 0;
		
	  
		  // Clear previous error messages
		  $('.errorspan').html('');
		  if (!zipcode) {
			errors++;
			$('.errorzipcode').html('<span class="errorspan">Please enter the  zipcode </span>');
		}else{
			$('.errorzipcode').html('');
		}
		if (!username) {
			errors++;
			$('.errortab_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(username){
			$('.errordescription').html('');
		}
		
		if (!name) {
			errors++;
			$('.errortab_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errortab_title').html('');
		}
		if (!rating) {
			errors++;
			$('.errorrating').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorrating').html('');
		}
		if (!reviews) {
			errors++;
			$('.errorreviews').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorreviews').html('');
		}
		if (!phoneno) {
			errors++;
			$('.errorphoneno').html('<span class="errorspan">Contact is required</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}
		if (!description) {
			errors++;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		if (!selectedInsuranceOption) {
			errors++;
			$('.errorInsuranceOption').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedInsuranceOption){
			$('.errorInsuranceOption').html('');
		}
		if (!selectedAmenityOption) {
			errors++;
			$('.erroramenities').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.erroramenities').html('');
		}
		// if (this.state.editid==0) {
		// 	if (!image) {
				
		// 		formIsValid = false;
		// 		$('.errorfile').html('<span class="errorspan">Please fill the feild</span>');
		// 			}else{
		// 				$('.errorfile').html('');
		// 			}
		// 			if (image) {		
		// 	if (!image.name.match(/\.(jpg|jpeg|png|svg)$/)) {
				
		// 		formIsValid = false;
		// 			 $('.errorfile').html('<span class="errorspan">Please select valid type</span>');
				   
		// 	  }else{
		// 		$('.errorfile').html('');
		// 	  }
		// 	}
		// }
		if(errors>0) {
			setTimeout(function () {
			scrollToTopValidate();
			}, 100);
			return false;
			}
			else {
			return true;
			}
	
    }
	handleClickSelecetCategory = (event) => {
		const newCategoryId = event.target.value;
		console.log(newCategoryId,'new');
		this.setState({ category_id: newCategoryId });
	};
	
	handleChangeCategory = selectedCategory => {

		this.setState({ selectedCategory});
		this.setState({ categoryvalue : selectedCategory.value });
 	}
	 handleinsuranceChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});
		
		this.setState({selectedInsuranceOption:selCategory});
		this.setState({  selectedInsurancevalue : selCategoryValue});
 	};
	 handlespecialist = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');
		this.setState({
			specialist_id: key,	
		})
	}
	handlamenityChange = (selOption) => {
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});
		
		this.setState({selectedAmenityOption: selCategory});
		this.setState({  selectedAmenityevalue : selCategoryValue});
	};
	handleChangeaddress = location => {
		this.setState({ location });
	  };
	  handleSelect = async (location,placeId) => {
		/*    console.log(location,'location')
		   geocodeByAddress(location)
			 .then(results => getLatLng(results[0]))
			 .then(latLng => console.log('Success', latLng))
			 .catch(error => console.error('Error', error));
			*/
			 const results = await geocodeByAddress(location);
			 const latLng = await getLatLng(results[0]);
			 const [place] = await geocodeByPlaceId(placeId);
			 const { long_name: postalCode = '' } =
			 place.address_components.find(c => c.types.includes('postal_code')) || {};
			 this.setState({ location });
			 const city = place.address_components.find(
				(c) => c.types.includes('locality')
			  ).long_name;
			  const state = place.address_components.find(
				(c) => c.types.includes('administrative_area_level_1')
			  ).long_name;
	
			  const country = place.address_components.find(
				(c) => c.types.includes('country')
			  ).long_name;
			 console.log(postalCode,'postalCode')
			 this.setState({latitude : latLng['lat'],longitude : latLng['lng'] ,zipcode : postalCode, city, state ,country })
   
		 };
	  handleChangeGender = selectedgenderOption => {
        this.setState({selectedgenderOption})
        this.setState({  selectedgendervalue : selectedgenderOption.value});
    };
	confirmPopup = (id,imagepath,selected_image) => {
		this.setState({selectid: id,imagepath:imagepath,selected_image:selected_image})	
		$('.confirm-action').addClass('show');		
	  }
	  deleteimage(){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          provider_gallery_id  :this.state.selectid,
          provider_id  :this.state.provider_id,
          imagepath  :this.state.imagepath,
          selected_image  :this.state.selected_image, 
        };
        axios.post(apiUrl+"provider/deleteimage",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.setState({galleries:res.data.galleries})
              $('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
			  this.listproviderdata();
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}
	Confirmstatus = (confirmstatus) => {
		if(confirmstatus == 'yes'){
		  this.deleteimage();
		  $('.confirm-action').removeClass('show');
		}else{
		  $('.confirm-action').removeClass('show');	
		}
		  
	  }
	  opengalleryImages = () => {
		axios.get(apiUrl+'provider/getimagelist?provider_id='+this.state.provider_id)
		.then(res => {
			if(res.data.status == 'success'){
				this.setState({galleryimagelist: res.data.imagelist})
			}else{
			   
			}
		});
		this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
   }
	  removeImagebyindex = (indexvalue) => {
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
	   
		for (let i = 0; i < list.length; i++) {
		  this.imageArray.push(list[i]);
		  this.fileArray.push(URL.createObjectURL(list[i]));
		}
	  
		  this.setState({ user_image: this.imageArray })
		  
	  }
	  uploadMultipleFiles = (e) =>{
		this.fileObj = [];
	
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				//this.fileArray.push(this.fileObj[0][i])
				this.imageArray.push(this.fileObj[0][i])
	
			}
			// this.setState({ user_image: this.imageArray })
			this.setState({ user_image: this.imageArray,galleryimg:2,selectedimages:[] })		
		
		}
  render() {
	var current = this;
	const navigate = this.props.navigate;
		var imagepath =  "provider";
		const loader =  mediaUrl+imagepath+'/' +this.state.image_name;
       var previews = <img className="img_class" src={loader} alt="" />;
  	  const {selectedOption ,selectedimages, galleries ,selectedgenderOption} = this.state;
		var image_path =  "gallery";
		if(this.state.editid==1){
			
			console.log(1)
			var loaderimg = mediaUrl+image_path+'/' +this.state.image_preview;
			var preview = <img className="img_class" src={loaderimg} alt="" />;
		}else {
		
			
			console.log(2) 
			var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	
		}
		
		

				let imageslist = [];

				let indexlength =  imageslist.length;
	
				const imgThumblist = this.fileArray && this.fileArray.map((url,index) => (
					<li className="thumb" key={indexlength+index}  >
						<span><img src={url}  alt="..." /></span>
						<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					</li>
				))

				const galleryhub = selectedimages && selectedimages.map((image,index) => (
					<li className="thumb" key={index}  >
						<span><img src={mediaUrl+image_path+'/'+image} alt={index}  width="100px" height="100px"/></span>
						<a href="javascript:void(0);" onClick={this.removeImage.bind(this,index)}>X</a>
					</li>
				))

  	
    return (
      <div className="wrapper"> 
		<div className="confirm-action showconvey">
		<p>You can't upload more than five images</p>
		<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">OK</a>
		</div>
		<div className="confirm-action">
			<p>Are you sure! you want to delete this image?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
	  <Header />
    <MenuSidebar currentpage="provider" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="content-wrapper">
				<a onClick={() => navigate(-1)}>Back</a>   
			</div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Username:</label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} autoComplete="off"/>
					<div className="errorprovidername"></div>
				</div>
				<div className="form-group">
					<label>Email:</label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
					<div className="erroremail"></div>
				</div>
				<div className="form-group">					
						<label>Gender:</label>
						   <Select 
						   value={selectedgenderOption?selectedgenderOption:{ value: 'male', label: 'Male'}}
                            options={lang.common.gender_option} 
                            onChange={this.handleChangeGender}
                            />
					</div>
						<div className="form-group">
							<input  type="checkbox"  checked={this.state.isCashOnly}  onChange={this.handleCashOnlyChange}  />
							<label>No Insurance (Cash Only):</label>
						</div>
						{!this.state.isCashOnly && (
							<div className="form-group">
							<label>Insurance :</label>
							<Select 
								value={this.state.selectedInsuranceOption ? this.state.selectedInsuranceOption : ""}
								options={this.state.insuranceList} 
								onChange={this.handleinsuranceChange.bind(this)}
								isSearchable={true}
								isMulti
							/>
							<div className='errorinsurance'></div>
							</div>
						)}
						<div className="form-group">
							<label> Specialties:</label>
							<Select
								value={this.state.selectedSpecialtiesOption ? this.state.selectedSpecialtiesOption : null}
								options={this.state.specialist}
								onChange={this.handleSpecialtiesChange.bind(this)}
								isSearchable={true}
							/>
					</div>
					<div className="form-group">	
							<div className="form-group">
								<label>Purpose:</label>
								<Select 
								value={this.state.selectedpurposeOption?this.state.selectedpurposeOption:""}
								options={this.state.preferredpurposelist} 
								onChange={this.handlepurposetypeChange.bind(this)}
								isSearchable={true}
								isMulti
								/>
							</div>
					
					</div>	
					<div className="form-group">
						<label>Rating:</label>
						<input type="text" name="rating" onChange={this.handleInputChange} className="form-control" value={this.state.rating} autoComplete="off"/>
						<div className="errorrating"></div>
					</div>
					
				   <div className="default-font">
				   </div>
				   <div className="form-group">
							<label>Location:</label>
							<PlacesAutocomplete	value={this.state.location}	onChange={this.handleChangeaddress}	onSelect={this.handleSelect}			>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<input  type="text" name="location" className="form-control" value={this.state.location}	
									{...getInputProps({
										placeholder: 'Search Places ...',
										className: 'location-search-input',
									})}
									/>
									<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
										// inline style for demonstration purpose
										const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
										return (
										<div
											{...getSuggestionItemProps(suggestion, {
											className,
											style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
										);
									})}
									</div>
								</div>
								)}
							</PlacesAutocomplete>
							<div className="errorlocation"></div>
					   
					    </div>
				{/* <div className="form-group">
					<label>Image:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					 {loader_image_preview?<a href="javascript:void(0);">X</a>:''} 
					<div className="errorfile"></div>  
				</div>  */}
					{galleries!=''?
			  		 <div className="form-group">
					<div>
						<label>Galleries</label>
						<div>
							{galleries.map((item) => (
							<div key={item.rehab_gallery_id}>
								<img width="100px" height="100px" src={mediaUrl+'/'+item.image_path+'/'+item.image} alt={`Image ${item.rehab_gallery_id}`}  />
								<Link onClick={this.confirmPopup.bind(this, item.provider_gallery_id,item.image_path,item.image)} className="" title="Delete">X</Link>
							</div>
							))}
						</div>
   	 				</div>
				</div>:''}
					<div className="form-group"> 
										<label>Image Gallery<span class="required" style={{ color: "red" }} > </span>(JPG,SVG,JPEG, PNG, GIF)</label>
											<div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											<span className="profile_btn">
											<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
											</div>
								 <ul className="carimagelisting">
								{/*imageslist*/}
								{imgThumblist}
								</ul>
					<div className="erroruser_image"></div>
				</div>
								
				<div className="form-group">
					<label>Upload license  (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} >* </span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					<div className="errorimage"></div> 
					{!this.state.image_preview && (
					<div className="form-group">
						{previews}
					</div>
					)}
					<div className="errorloaderimage"></div> 
					{this.state.image_preview && (
						<div className="image-preview">
						<img src={this.state.image_preview} alt="Preview" className='img_class' />
						</div>
					)} 
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					 
				</div> 	
			</div>
                  
			<div className="form-right">
				<div className="form-group">
					<label>Name :</label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} autoComplete="off"/>
					<div className="errorname"></div>
				</div>
				<div class="form-group">
					<label>Academy Degree :</label>
					<input type="text" name="degree_name"className="form-control"  autocomplete="off" value={this.state.degree_name}  onChange={this.handleInputChange} />
					<div class="errordegree_name"></div>
				</div>
				<div className="form-group">
					<label>Phone:</label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				<div className="form-group">	
						<label>Amenities</label>	
						<Select 
						value={this.state.selectedAmenityOption?this.state.selectedAmenityOption:""}
						options={this.state.amenitynamelist} 
						onChange={this.handlamenityChange.bind(this)}
						isSearchable={true}
						isMulti
						/>
						<div className='erroramenities'></div>
				    </div>  
					<div className="form-group">
						<label>Booking Type:</label>
						<Select 
						value={this.state.selectedbookingOption?this.state.selectedbookingOption:""}
						options={lang.common.booking_type} 
						onChange={this.handlebookingtypeChange.bind(this)}
						isSearchable={true}
						isMulti
						/>
						<div className='errorbookingtype'></div>
					</div>      
					{/* <div className="form-group">
						<label>
							Specialties:
						</label>
						<Select
							value={this.state.selectedSpecialtiesOption ? this.state.selectedSpecialtiesOption : []}
							options={this.state.specialist}
							onChange={this.handleSpecialtiesChange.bind(this)}
							isSearchable={true}
							isMulti
						/>
					</div> */}
					
					<div className="form-group">
						<label>Reviews:</label>
						<input type="text" name="reviews" onChange={this.handleInputChange} className="form-control" value={this.state.reviews} autoComplete="off"/>
						<div className="errorreviews"></div>
					</div>
					
					<div className="form-group">					
						<label>Zipcode : </label>
							<input type="text" name='zipcode' onChange={this.handleInputChange} className='form-control' value={this.state.zipcode} />
						<div className="errorzipcode"></div>
					</div>	
					<div className="form-group">	
							<div className="form-group">
								<label>Preferred Location:</label>
								<Select 
								value={this.state.selectedlocationOption?this.state.selectedlocationOption:""}
								options={this.state.preferredlocationlist} 
								onChange={this.handlelocationtypeChange.bind(this)}
								isSearchable={true}
								isMulti
								/>
							</div>
					
					</div>	
				<div className="form-group">					
					<label>About: </label>
						<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
					<div className="errordescription"></div>
				</div>
				<div className="form-group">					
					<label>Status :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
				


			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default (withRouter(Edit));