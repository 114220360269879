import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl ,mediaUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
	geocodeByPlaceId
  }  from 'react-places-autocomplete';
const initialState = {
			providername:'',
			specialties:'',
			description:'',
			email:'',
			phoneno:'',
			status:'',
			user_password: '',
            user_confirm_password: '',
            new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'}, 
			selectedgenderOption:{value:'male', label: 'Male'},
			selectedvalue:'',
			disablepasssword:true,
			domainlist:[],
			selectedDomainOption:'',
			selectedDomainvalue:'',
			domainlistlength:'',
			maxCharacterLimit : 20,
			education:'',
			insurance:'',
			selectedgendervalue:'',
			selectedSpecialtiesOption: '',
			specialtiesList:'',
			selectedSpecialtiesvalue:'',
			selectedInsuranceOption: '',
			insuranceList:'',
			selectedInsurancevalue:'',
			name:'',
			image_preview:'',
			image_name:'',
			image:'',
			locationlist: [{id: '', name: 'Select Your Location'}],
			zipcode: '',
			reviews:'',
			rating:'',
			latitude:'',
			longitude:'',
			amenitynamelist:'',
			selectedAmenityOption:'',
			selectedAmenityevalue:'',
			user_image: '',	
			selectedimages:[],
			galleryimg: 'No',
			imagelist:'',
			country:'',
			degree_name:'',
			isCashOnly: true,
			selectedbookingOption:'',
			selectedBookingvalue:'',
			selectedlocationOption:"",
			selectedlocationvalue:"",
			preferredlocationlist:"",
			selectedpurposeOption:'',
			selectedpurposevalue:'',
			preferredpurposelist:"",
		};
		
		
		const withRouter = WrappedComponent => props => {
			const params = useParams();
			const  navigate = useNavigate();
			// etc... other react-router-dom v6 hooks
		  
			return (
			  <WrappedComponent
				{...props}
				params={params}
				navigate ={navigate}
				// etc...
			  />
			);
		  };
class Add extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	    this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
       this.handleChange = this.handleChange.bind(this);
	   this.handleSpecialtiesChange = this.handleSpecialtiesChange.bind(this);
	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
	   this.removeImagebyindex = this.removeImagebyindex.bind(this);
	   this.handlelocationtypeChange = this.handlelocationtypeChange.bind(this);
	   this.handlepurposetypeChange = this.handlepurposetypeChange.bind(this);
    }
	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }
    }
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handleChangeGender = selectedgenderOption => {
        this.setState({selectedgenderOption})
		console.log(selectedgenderOption.value,'testigender')
        this.setState({  selectedgendervalue : selectedgenderOption.value});
    };
	handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
	}
	handlepurposetypeChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});

			
		this.setState({selectedpurposeOption:selCategory});
		this.setState({  selectedpurposevalue : selCategoryValue});
 	};
	amenitynamelist (postobject=''){
		var qs = require('qs');
		var admin_id = localStorage.getItem("admin_id");

		var postobject = {
			admin_id: admin_id,
		};  
		axios.get(apiUrl + "provider/getamenitynamelist", { params: postobject }).then(res => {

			if(res.data.status === 'success'){
				console.log(res.data.amenitynamelist,'providerdata')
				 this.setState({  amenitynamelist:res.data.amenitynamelist });
				
			}
		}); 

	}
	handlelocationtypeChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});

			
		this.setState({selectedlocationOption:selCategory});
		this.setState({  selectedlocationvalue : selCategoryValue});
 	};

	specialtiesListData(postobject=''){
		var qs = require('qs');
		var admin_id = localStorage.getItem("admin_id");
		var loggedas = localStorage.getItem("loggedas");
		var postobject = {
			activePage: 1,
			search_all: '',
			admin_id: admin_id,
			loggedas: loggedas
		};    

		axios.post(apiUrl+"provider/listdetails",qs.stringify(postobject)).then(res => {
			if(res.data.status === 'success'){
				var insuranceList = res.data.insurancelist;
				var specialtieslist = res.data.specialtieslist;
				let i=0;
				var categorylists = [];
				for(i; i<insuranceList.length; i++){
					insuranceList[i] = { value: insuranceList[i]['id'], label: insuranceList[i]['name'] };
					//console.log(specialtiesList,"categorylists12");
				}
				let j=0;
				for(j; j<specialtieslist.length; j++){
					specialtieslist[j] = { value: specialtieslist[j]['id'], label: specialtieslist[j]['name'] };
					//console.log(specialtieslist,"categorylists12sss");
				}
				this.setState({  specialtieslist:specialtieslist });
				this.setState({  insuranceList:insuranceList });
			}
		}); 
	  }
	componentDidMount() {
		this.specialtiesListData();
		this.listloadlocations();
		this.amenitynamelist();
		var  selecteddomainOption ='';
		var selectedDomainvalue ='';
		document.title = PageTitle('Add user');
		if(localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
		}
		let domainlist = [];
		axios.post(apiUrl+"user/domainlist").then(res => {
			if(res.data.status == "success"){
				let i=0;
				let domainlists = res.data.domainlist;
				for(i; i<domainlists.length; i++){
					
					domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
				}
				
				if(res.data.domainlist.length==1){
				let	j=0;
					for(j; j<res.data.domainlist.length; j++){
						
					 selecteddomainOption = { value:domainlists[j]['domain_id'], label: domainlists[j]['domain_name'] };
				    selectedDomainvalue = domainlists[j]['domain_id'];	
				}
					
					this.setState({selectedDomainOption:selecteddomainOption})
					this.setState({selectedDomainvalue:selectedDomainvalue})
				}

			}       
		});
		

		

    }

	listloadlocations=()=>{
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'provider/locationslist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						preferredlocationlist:res.data.locationslist,
						preferredpurposelist:res.data.purposelist
					});
				
				}else{
				}
			});
	}
	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    } 
	handleCashOnlyChange = (e) => {
		this.setState({ isCashOnly: e.target.checked });
		if (e.target.checked) {
		  this.setState({ selectedInsuranceOption: null });
		}
	  };
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file,
			fileExtension:extension,
			
		  });
		};
		reader.readAsDataURL(file);
	  }
	  handleChangeaddress = location => {
		this.setState({ location });
	  };
	  handleSelect = async (location,placeId) => {
     	/*    console.log(location,'location')
		geocodeByAddress(location)
		  .then(results => getLatLng(results[0]))
		  .then(latLng => console.log('Success', latLng))
		  .catch(error => console.error('Error', error));
      */
		  const results = await geocodeByAddress(location);
		  const latLng = await getLatLng(results[0]);
		  const [place] = await geocodeByPlaceId(placeId);
		  const { long_name: postalCode = '' } =
			place.address_components.find(c => c.types.includes('postal_code')) || {};
		  this.setState({ location });
		  const city = place.address_components.find(
			(c) => c.types.includes('locality')
		  ).long_name;
		  const state = place.address_components.find(
			(c) => c.types.includes('administrative_area_level_1')
		  ).long_name;

		  const country = place.address_components.find(
			(c) => c.types.includes('country')
		  ).long_name;
		  console.log(city,'postalCode',state)
		  this.setState({latitude : latLng['lat'],longitude : latLng['lng'] ,zipcode : postalCode ,city, state ,country})

	  };
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				var gender = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
					status =formPayload.selectedOption.value;
				}else{
					status = formPayload.selectedvalue;
				}
				if(formPayload.selectedgendervalue === '' && formPayload.selectedgendervalue!== 0){
						gender =formPayload.selectedgenderOption.value;
					}else{
						gender = formPayload.selectedgendervalue;
					}
					console.log(formPayload.selectedAmenityevalue,'aminities')
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					userid       : formPayload.userid,
					domain_id       : formPayload.selectedDomainvalue,
					name     : formPayload.name,
					insurance: formPayload.selectedInsurancevalue,
					description     : formPayload.description,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					specialties     : formPayload.selectedSpecialtiesvalue,
					gender: gender,
					username     : formPayload.username,
					licence : formPayload.image,
					location:formPayload.location, 
					zipcode : formPayload.zipcode,
					latitude: formPayload.latitude,
					longitude: formPayload.longitude,
					status       :  status,
					rating:formPayload.rating,
					reviews:formPayload.reviews,
					amenityids:formPayload.selectedAmenityevalue,
					licenseStatus:'Verified',
					city:formPayload.city,
					state:formPayload.state,
					country:formPayload.country,
					degree_name:formPayload.degree_name,
					charge_type: this.state.isCashOnly ? 'Cash only' : 'Insurance',
					bookingtype:formPayload.selectedBookingvalue,
					locationtype:formPayload.selectedlocationvalue,

					
					
					
				};
				let formData = new FormData();
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}
					var filesdata = this.state.user_image;
						var galleryimg = this.state.galleryimg;
						if(galleryimg==2){
						for (var i in filesdata) {
							formData.append('multiple_images[]',filesdata[i])
						}
					}
					var selectedimages;
				
					selectedimages = this.state.selectedimages;
					if(galleryimg==1){

						for (var i in selectedimages) {
							formData.append('images[]',selectedimages[i])
						}	
					}
				console.log('domain_id',postObject);
				axios.post(apiUrl+"provider/add",formData).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   this.setState({
							username:'',			
							firstname:'',
							lastname:'',
							email:'',
							phoneno:'',
							password: '',
							confirm_password:''
						   });
						   setTimeout(
						   function() {
							
							   $('.success_message').html('');
									this.props.navigate('/provider');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {username,selectedbookingOption, galleryimg,selectedimages,user_image, selectedSpecialtiesOption, rating, reviews, selectedAmenityOption,  selectedInsuranceOption, name,location, description, email, phoneno, image, user_confirm_password,selectedDomainvalue,maxCharacterLimit, zipcode} = this.state;
		let errors = 0;
		const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let maxSize = 1000000;//1 mb
		const nameRegex = /^[A-Za-z\s.]+$/;
		var galleryhubCount =  (selectedimages)?selectedimages.length:0;
       var selectedlocalCount =  (user_image && user_image.length)?user_image.length:0;

		if (!selectedSpecialtiesOption) {
			errors++;
			$('.errorspc').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorspc').html('');
		}
		if (!selectedbookingOption) {
			errors++;
			$('.errorbookingtype').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorbookingtype').html('');
		}
		
		
		// if (!selectedInsuranceOption) {
		// 	errors++;
		// 	$('.errorinsurance').html('<span class="errorspan">Please fill the field</span>');
		// } else{
		// 	$('.errorinsurance').html('');
		// }
		if (!selectedAmenityOption) {
			errors++;
			$('.erroramenities').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.erroramenities').html('');
		}
		
		if (!username) {
			errors++;
			$('.errorprovidername').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorprovidername').html('');
		}
		if (!rating) {
			errors++;
			$('.errorrating').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorrating').html('');
		}
		if (!reviews) {
			errors++;
			$('.errorreviews').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorreviews').html('');
		}
		// if (!image) {
		// 	errors++;
		// 	$('.errorimage').html('<span class="errorspan">Please select profile image</span>');
		// } else{
		// 	$('.errorimage').html('');
		// }
		if (!name) {
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorname').html('');
		}
		if (!phoneno) {
			errors++;
			$('.errorphoneno').html('<span class="errorspan">Contact is required</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}
		
		
		if (!location) {
			errors++;
			$('.errorlocation').html('<span class="errorspan">Please fill the field</span>');
		} else{
			$('.errorlocation').html('');
		}

		 
			if (!description) {
				errors++;
				$('.errordescription').html('<span class="errorspan">Please enter the  description </span>');
			}else{
				$('.errordescription').html('');
			}
			
			if (!zipcode) {
				errors++;
				$('.errorzipcode').html('<span class="errorspan">Please enter the  zipcode </span>');
			}else{
				$('.errorzipcode').html('');
			}
		
		if (!email) {
			errors++;
			$('.erroremail').html('<span class="errorspan">Email is required</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
		}
		// if (user_image) {
	    //          for (let i = 0; i < user_image.length; i++) 
		// 		 {

		// 			const file = user_image[i];
		// 			const filename = file.name;

		// 			// if (file.size > maxSize) {
		// 			// 	errors++;
					
		// 			// $('.erroruser_image').html('<span class="errorspan">'+filename+'File is too large. Max size is 1MB</span>');
		// 			// continue;
		// 			// }
			
		// 			if (!allowedTypes.includes(file.type)) {
		// 				errors++;
					
		// 			$('.erroruser_image').html('<span class="errorspan"> '+filename+' file type is Invalid.</span>');

		// 			continue;
		// 			}
	    //         }
	    //    }
		  
		// 	if(galleryimg==1&&galleryhubCount >= 5){
					
		// 			errors++;
		// 			$('.erroruser_image').html('<span class="errorspan">You can only upload up to 5 images</span>');
		// 			//fileErrors.push('You can only upload up to 10 images.');
				
		// 	}else{
		// 		$('.erroruser_image').html('<span class="errorspan"></span>');
		// 	}
		// 	if(galleryimg==2&&selectedlocalCount >= 5){
					
		// 		errors++;
		// 		$('.erroruser_image').html('<span class="errorspan">You can only upload up to 5 images</span>');
		// 		//fileErrors.push('You can only upload up to 10 images.');
			
		// }else{
		// 	$('.erroruser_image').html('<span class="errorspan"></span>');
		// }
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

		

    componentWillReceiveProps(Props){

    		if(Props.useradd !== this.props.useradd){
    		if(Object.keys(Props.useradd).length > 0){
				this.setState({Loading:false});

				if(Props.useradd[0].status === "success"){

				 $('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
				scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/user'));
						}
						.bind(this),
						3000
						);
					this.setState({username:'',firstname:'',lastname:'',email:'',phoneno:'',user_password:'',user_confirm_password:''})
					}else if(Props.useradd[0].status === "erroremail"){

					 $('.erroremail').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.erroremail').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorphone"){

					 $('.errorphoneno').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorphoneno').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorusername"){

					 $('.errorusername').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorusername').html('');
							}
							.bind(this),
							3000
							);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
						scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	handleClickSelecet = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');
		this.setState({
				domain_id: key,
				
		})


	}
	handlebookingtypeChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});

			
		this.setState({selectedbookingOption:selCategory});
		this.setState({  selectedBookingvalue : selCategoryValue});
 	};
	 handleSpecialtiesChange = (selOption) => {
		if (selOption) {
		  this.setState({ 
			selectedSpecialtiesOption: { label: selOption.label, value: selOption.value },
			selectedSpecialtiesvalue: selOption.value 
		  });
		} else {
		  this.setState({ 
			selectedSpecialtiesOption: null,
			selectedSpecialtiesvalue: null 
		  });
		}
	  };
	 handleinsuranceChange = (selOption) => {
       
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});
		
		this.setState({selectedInsuranceOption: selCategory});
		this.setState({  selectedInsurancevalue : selCategoryValue});
 	};
	 handlamenityChange = (selOption) => {
		let selCategory = [];
		let selCategoryValue = [];
		selOption.map(item => {
			selCategoryValue.push(item.value);
			selCategory.push({ label: item.label, value: item.value });
		});
		
		this.setState({selectedAmenityOption: selCategory});
		this.setState({  selectedAmenityevalue : selCategoryValue});
	};
	uploadMultipleFiles = (e) =>{
		this.fileObj = [];
	
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				//this.fileArray.push(this.fileObj[0][i]
				this.imageArray.push(this.fileObj[0][i])
	
			}
			this.setState({ user_image: this.imageArray,galleryimg:2,selectedimages:[] })		
		}
		 removeImagebyindex = (indexvalue) => {
		   
		  var list = [...this.imageArray];
		  this.imageArray = [];
		  this.fileArray = [];
		  list.splice(indexvalue, 1);
		 
		  for (let i = 0; i < list.length; i++) {
			this.imageArray.push(list[i]);
			this.fileArray.push(URL.createObjectURL(list[i]));
		  }
		
			this.setState({ user_image: this.imageArray })	
	}
  render() {
	const navigate = this.props.navigate;
  	const {selectedOption,specialtieslist ,insuranceList,selectedgenderOption,selectedSpecialtiesOption ,selectedimages} = this.state;
	  var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;

	  let imageslist = [];

	  let indexlength =  imageslist.length;
	  let image_path =  "gallery";
	  
	  const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
					  <li className="thumb" key={indexlength+index}  >
						  <span><img src={url}  alt="..." /></span>
						  <a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					  </li>
				  ))

				  const galleryhub =   selectedimages.map((image,index) => (
					<li className="thumb" key={index}  >
						<span><img src={mediaUrl+image_path+'/'+image} alt={index}  width="100px" height="100px"/></span>
						<a href="javascript:void(0);" onClick={this.removeImage.bind(this,index)}>X</a>
					</li>
				))
				
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="provider" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div className="content-wrapper">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div>
			<div className="title">
				<h4>Add Provider</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Username:</label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} autoComplete="off"/>
					<div className="errorprovidername"></div>
				</div>
				<div className="form-group">
					<label>Email:</label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
					<div className="erroremail"></div>
				</div>
                  <div className="errorpassword"></div>
				  	<div className="form-group">					
						<label>Gender:</label>
						   <Select 
						   value={selectedgenderOption?selectedgenderOption:{ value: 'male', label: 'Male'}}
                            options={lang.common.gender_option} 
                            onChange={this.handleChangeGender}
                            />
					</div>
					<div className="form-group">
						<label>No Insurance (Cash Only):</label>
						<input 
							type="checkbox" 
							checked={this.state.isCashOnly} 
							onChange={this.handleCashOnlyChange} 
						/>
						</div>
					{/* <div className="form-group">	
						<label>Insurance :</label>
						<Select 
						value={this.state.selectedInsuranceOption?this.state.selectedInsuranceOption:""}
						options={this.state.insuranceList} 
						onChange={this.handleinsuranceChange.bind(this)}
						isSearchable={true}
						isMulti
						/>
					<div className='errorinsurance'></div>
					</div> */}
					{!this.state.isCashOnly && (
						<div className="form-group">
							<label>Insurance :</label>
							<Select 
							value={this.state.selectedInsuranceOption ? this.state.selectedInsuranceOption : ""}
							options={this.state.insuranceList} 
							onChange={this.handleinsuranceChange.bind(this)}
							isSearchable={true}
							isMulti
							/>
							<div className='errorinsurance'></div>
						</div>
						)}
						<div className="form-group">
						<label>Specialties:</label>
						<Select 
							value={this.state.selectedSpecialtiesOption || ""}
							options={this.state.specialtieslist} 
							onChange={this.handleSpecialtiesChange.bind(this)}
							isSearchable={true}
						/>
						<div className='errorspc'></div>
					</div>
					<div className="form-group">	
							<div className="form-group">
								<label>Purpose:</label>
								<Select 
								value={this.state.selectedpurposeOption?this.state.selectedpurposeOption:""}
								options={this.state.preferredpurposelist} 
								onChange={this.handlepurposetypeChange.bind(this)}
								isSearchable={true}
								isMulti
								/>
							</div>
					
					</div>
					<div className="form-group">
						<label>Rating:</label>
						<input type="text" name="rating" onChange={this.handleInputChange} className="form-control" value={this.state.rating} autoComplete="off"/>
						<div className="errorrating"></div>
					</div>
					<div className="form-group">
						<label>Location:</label>
							<PlacesAutocomplete	value={this.state.location}	onChange={this.handleChangeaddress}	onSelect={this.handleSelect}			>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<input  type="text" name="location" className="form-control" value={this.state.location}	
									{...getInputProps({
										placeholder: 'Search Places ...',
										className: 'location-search-input',
									})}
									/>
									<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
										// inline style for demonstration purpose
										const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
										return (
										<div
											{...getSuggestionItemProps(suggestion, {
											className,
											style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
										);
									})}
									</div>
								</div>
								)}
							</PlacesAutocomplete>
							<div className="errorlocation"></div>
					    </div>
					{/* <div className="form-group">
						<label>Image:</label>
						<div className="choose-file">
						{this.state.isHidden && <div className="image_success"></div>}
						<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
						</div>
						{preview} */}
						{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
							{/* <div className="errorimage"></div>  
						</div>   */}
							<div className="form-group"> 
										<label>Upload Gallery<span class="required" style={{ color: "red" }} > </span>(JPG,SVG,JPEG, PNG, GIF)</label>
											<div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											<span className="profile_btn">
											<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
											</div>
									<ul className="carimagelisting">
										{/*imageslist*/}
									{gallerylocal}
									</ul>
									<div className="erroruser_image"></div>
								</div>
				<div className="form-group">
					<label>Upload license  (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} >* </span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					<div className="errorimage"></div> 
					{preview}
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					 
				</div> 
				</div>
				<div className="form-right">
				<div className="form-group">
					<label>Name:</label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} autoComplete="off"/>
					<div className="errorname"></div>
				</div>
				<div class="form-group">
					<label>Academy Degree :</label>
					<input type="text" name="degree_name"className="form-control"  autocomplete="off" value={this.state.degree_name}  onChange={this.handleInputChange} />
					<div class="errordegree_name"></div>
				</div>
				<div className="form-group">
					<label>Phone:</label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
					<div className="form-group">	
						<label>Amenities :</label>
						<Select 
						value={this.state.selectedAmenityOption?this.state.selectedAmenityOption:""}
						options={this.state.amenitynamelist} 
						onChange={this.handlamenityChange.bind(this)}
						isSearchable={true}
						isMulti
						/>
						<div className='erroramenities'></div>
					</div>
					
			
					<div className="form-group">
						<label>Booking Type:</label>
						<Select 
						value={this.state.selectedbookingOption?this.state.selectedbookingOption:""}
						options={lang.common.booking_type} 
						onChange={this.handlebookingtypeChange.bind(this)}
						isSearchable={true}
						isMulti
						/>
						<div className='errorbookingtype'></div>
					</div> 
					
					<div className="form-group">
						<label>Reviews:</label>
						<input type="text" name="reviews" onChange={this.handleInputChange} className="form-control" value={this.state.reviews} autoComplete="off"/>
						<div className="errorreviews"></div>
					</div>
				<div className="form-group">					
					<label>Zipcode : </label>
						<input type="text" name='zipcode' onChange={this.handleInputChange} className='form-control' value={this.state.zipcode} />
					<div className="errorzipcode"></div>
				</div>
				<div className="form-group">	
							<div className="form-group">
								<label>Preferred Location:</label>
								<Select 
								value={this.state.selectedlocationOption?this.state.selectedlocationOption:""}
								options={this.state.preferredlocationlist} 
								onChange={this.handlelocationtypeChange.bind(this)}
								isSearchable={true}
								isMulti
								/>
							</div>
					
					</div>	
					<div className="form-group">					
					<label>About : </label>
						<textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} />
					<div className="errordescription"></div>
				</div>
				
				<div className="default-font">
					<div className="form-group">					
						<label>Status:</label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange}
								/>
					</div>
				</div>
					
					</div>
					
				</div>
			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);