import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';


import LocationList from "./components/Location/List";
import LocationEdit from "./components/Location/Edit";
import LocationAdd from "./components/Location/Add";
import LocationView from "./components/Location/View";

import './common/css/font-awesome.min.css';

import Adminprofile from './components/Account/Adminprofile';
import Login from './components/Account/Login';
import Logout from "./components/Account/Logout";
import Setting from "./components/Account/Setting";
import Changepassword from "./components/Account/Changepassword";
import Forgetpassword from "./components/Account/Forgetpassword";
import Resetpassword from "./components/Account/Resetpassword";
import Company from "./components/Company/List";
import CompanyAdd from "./components/Company/Add"
import CompanyEdit from "./components/Company/Edit";



import UserList from "./components/User/List";
import UserEdit from "./components/User/Edit";
import UserAdd from "./components/User/Add";
import UserView from "./components/User/View";


import MedstoresList from "./components/Medstores/List";




import AppointmentList from "./components/Appointment/List";
import AppointmentView from "./components/Appointment/View";
// import AppointmentAdd from "./components/Appointment/Add";
// import AppointmentAssigned from "./components/Appointment/AssignedList";
// import AppointmentObservation from "./components/Appointment/ObservationList";
// import AppointmentAssignedView from "./components/Appointment/AssignedView";
// import AppointmentObservationView from "./components/Appointment/ObservationView";

import MemList from "./components/Membership/List";
import MemEdit from "./components/Membership/Edit";
import MemAdd from "./components/Membership/Add";
import MemView from "./components/Membership/View";

import MembershipCategoryList from "./components/MembershipCategory/List";
import MembershipCategoryAdd from "./components/MembershipCategory/Add";
import MembershipCategoryEdit from "./components/MembershipCategory/Edit";

import Fontlist from "./components/Fonts/List";
import FontAdd from "./components/Fonts/Add";
import FontEdit from "./components/Fonts/Edit";

import TemplateList from './components/Templates/List';
import TemplateAdd from './components/Templates/Add';
import TemplateEdit from './components/Templates/Edit';

import DashboardList from "./components/Dashboard/List";
import Gallery from "./components/Gallery/Gallery";

import FaqCategoryList from "./components/FaqCategory/List";
import FaqCategoryAdd from "./components/FaqCategory/Add";
import FaqCategoryEdit from "./components/FaqCategory/Edit";

import FaqList from "./components/Faq/List";
import FaqAdd from "./components/Faq/Add";
import FaqEdit from "./components/Faq/Edit";
import FaqImport from "./components/Faq/ImportFaq";



import AiFeedList from "./components/AiFeed/List";
import AiImport from "./components/AiFeed/Import";

import TabList from "./components/TabManagement/List";
import TabEdit from "./components/TabManagement/Edit";
import TabAdd from "./components/TabManagement/Add";
import TabView from "./components/TabManagement/View";


import CompanyClone from "./components/Company/CompanyClone";

import SpecialtiesList from "./components/Specialties/List";
import SpecialtiesAdd from "./components/Specialties/Add";
import SpecialtiesEdit from "./components/Specialties/Edit";  



import InsuranceList from "./components/Insurance/List";
import InsuranceAdd from "./components/Insurance/Add";  
import InsuranceEdit from "./components/Insurance/Edit";


import ProviderList from "./components/Provider/List";
import ProviderEdit from "./components/Provider/Edit";
import ProviderAdd from "./components/Provider/Add"; 
import ProviderView from "./components/Provider/View"; 

import AmenityList from "./components/Amenities/List";
import AmenityAdd from "./components/Amenities/Add";
import AmenityEdit from "./components/Amenities/Edit";

import Medlist from "./components/Medproducts/List";
import MedAdd from "./components/Medproducts/Add";
import MedEdit from "./components/Medproducts/Edit";



import VisitList from "./components/VisitReasons/List";
import VisitsAdd from "./components/VisitReasons/Add";
import VisitEdit from "./components/VisitReasons/Edit";  



import RaiseDisputeList from "./components/RaiseDispute/List";
import RaiseDisputeAdd from "./components/RaiseDispute/Add";
import RaiseDisputeEdit from "./components/RaiseDispute/Edit";  



 import Productlist from "./components/Product/List";

 import DashboardManagement from "./components/DashboardManagement/List";
import DashboardAdd from "./components/DashboardManagement/Add";
import DashboardEdit from "./components/DashboardManagement/Edit";

import AdvertisementCategoryList from "./components/AdvertisementCategory/List";
import AdvertisementCategoryEdit from "./components/AdvertisementCategory/Edit";
import AdvertisementCategoryAdd from "./components/AdvertisementCategory/Add";



import AdvertisementList from "./components/Advertisement/List";
import AdvertisementEdit from "./components/Advertisement/Edit";
import AdvertisementAdd from "./components/Advertisement/Add";
import AdvertisementView from "./components/Advertisement/View";



import CMSList from "./components/CMS/List";
import CMSEdit from "./components/CMS/Edit";
import CMSAdd from "./components/CMS/Add";
import CMSView from "./components/CMS/View";



import './common/css/custom.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/profile" element={< Adminprofile />} />
      <Route path="/logout" element={< Logout />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/changepassword" element={<Changepassword />} />
      <Route path="/forget_password" component={Forgetpassword} />
      <Route path="/reset_password/:key" component={Resetpassword} />
      <Route path="/company" element={<Company />} />
      <Route path="/company-add" element={< CompanyAdd />} />
      <Route path="/company-edit/:ID" element={<CompanyEdit/>} />
      <Route path="/font-edit/:ID" element={<FontEdit />} />
      <Route path="/fonts" element={<Fontlist />} />
      <Route path="/font-add" element={<FontAdd />} />

      <Route path="/user" element={<UserList/>} />
      <Route path="/user-add" element={< UserAdd/>} />
      <Route path="/user-edit/:ID" element={<UserEdit/>} />
      <Route path="/user-view/:ID" element={<UserView/>} />

      <Route path="/medstores" element={<MedstoresList/>} />
      
      {/* <Route path="/store-view/:ID" element={<StoreView/>} /> */}
      <Route path="/gallery" element={<Gallery/>} />

    

      

     

      {/* <Route path="/appointments" element={<AppointmentList/>} />
      <Route path="/appointment-view/:ID" element={<AppointmentView/>} />
      <Route path="/appointment-add" element={<AppointmentAdd/>} />
      <Route path="/appointment-assigned" element={<AppointmentAssigned/>} />
      <Route path="/appointment-observation" element={<AppointmentObservation/>} />
      <Route path="/appointment-assignedview/:ID" element={<AppointmentAssignedView/>} />
      <Route path="/appointment-observationview/:ID" element={<AppointmentObservationView/>} /> */}

      
      <Route path="/membership" element={<MemList/>}  />
      <Route path="/membership-edit/:ID" element={<MemEdit/>} />
      <Route path="/membership-view/:ID" element={<MemView/>} />
      <Route path="/membership-add" element={<MemAdd/>} />

      <Route path="/membershipcategory" element={<MembershipCategoryList/>}  />
      <Route path="/membershipcategory-add" element={<MembershipCategoryAdd/>} />
      <Route path="/membershipcategory-edit/:ID" element={<MembershipCategoryEdit/>} />
      {/* <Route path="/user" element={UserList} />
      <Route path="/edit/:ID" element={UserEdit} />
      <Route path="/view/:ID" element={UserView} />
      <Route path="/user-add" element={UserAdd} />
      <Route path="/user-import" element={UserImport} /> */}

      <Route path="/templates" element={<TemplateList />} />
      <Route path="/template/add" element={<TemplateAdd/>} />
      <Route path="/templates-edit/:ID" element={<TemplateEdit />} />
      <Route path="/dashboard" element={<DashboardList />} /> 
      <Route path="/company-clone/:ID" element={<CompanyClone />} />
      <Route path="/faqcategory" element={<FaqCategoryList/>} />
      <Route path="/faqcategory-add" element={<FaqCategoryAdd/>} />
      <Route path="/faqcategory-edit/:ID" element={<FaqCategoryEdit/>} />  

      <Route path="/faq" element={<FaqList/>} />
      <Route path="/faq-add" element={<FaqAdd/>} />
      <Route path="/faq-edit/:ID" element={<FaqEdit/>} />
      <Route path="/faq-import" element={<FaqImport/>} />

      <Route path="/amenities" element={<AmenityList/>} />
      <Route path="/amenity-add" element={<AmenityAdd/>} />
      <Route path="/amenity-edit/:ID" element={<AmenityEdit/>} />
            
      <Route path="/aifeed" element={<AiFeedList/>} />
      <Route path="/aifeed-import" element={<AiImport/>} />
        <Route path="/tabmanagement" element={<TabList/>} />
        <Route path="/tabmanagement-edit/:ID" element={<TabEdit/>} />
        <Route path="/tabmanagement-add" element={<TabAdd/>} /> 

        <Route path="/tabmanagement-view/:ID" element={<TabView/>} />

        <Route path="/specialties" element={<SpecialtiesList/>} />
        <Route path="/specialties-add" element={<SpecialtiesAdd/>} />
        <Route path="/specialties-edit/:ID" element={<SpecialtiesEdit/>} />
        
        <Route path="/insurance" element={<InsuranceList/>} />
        <Route path="/Insurance-add" element={<InsuranceAdd/>} />
        <Route path="/insurance-edit/:ID" element={<InsuranceEdit/>} />


        <Route path="/provider" element={<ProviderList/>} />
        <Route path="/provider-add" element={<ProviderAdd/>} /> 
        <Route path="/provider-edit/:ID" element={<ProviderEdit/>} />
        <Route path="/provider-view/:ID" element={<ProviderView/>} />

        <Route path="/medproducts" element={<Medlist/>} />
        <Route path="/medproducts-add" element={<MedAdd/>} /> 
        <Route path="/medproducts-edit/:ID" element={<MedEdit/>} />

        <Route path="/visit" element={<VisitList/>} />
        <Route path="/visit-add" element={<VisitsAdd/>} />
        <Route path="/visit-edit/:ID" element={<VisitEdit/>} />
        
        <Route path="/raisedispute" element={<RaiseDisputeList/>} />
        <Route path="/raisedispute-add" element={<RaiseDisputeAdd/>} />
        <Route path="/raisedispute-edit/:ID" element={<RaiseDisputeEdit/>} />
  



        <Route path="/product" element={<Productlist/>} />
        <Route path="/dashboardmanagement" element={<DashboardManagement/>} />
        <Route path="/dashboardmanagement/edit/:ID" element={<DashboardEdit/>} />       
        <Route path="/dashboardmanagement-add" element={<DashboardAdd/>} />

        <Route path="/appointments" element={<AppointmentList/>} />
        <Route path="/appointment-view/:ID" element={<AppointmentView/>} />
     
        <Route path="/location" element={<LocationList/>} />
        <Route path="/location-edit/:ID" element={<LocationEdit/>} />
        <Route path="/location-view/:ID" element={<LocationView/>} />
        <Route path="/location-add" element={<LocationAdd/>} />

        <Route path="/advertisementcategory" element={<AdvertisementCategoryList/>} />
        <Route path="/advertisementcategory-edit/:ID" element={<AdvertisementCategoryEdit/>} /> 
        <Route path="/advertisementcategory-add" element={<AdvertisementCategoryAdd/>} />

        <Route path="/cms" element={<CMSList/>} />
        <Route path="/cms-edit/:ID" element={<CMSEdit/>} />
        <Route path="/cms-add" element={<CMSAdd/>} /> 

        <Route path="/cms-view/:ID" element={<CMSView/>} />

      
        
        <Route path="/advertisement" element={<AdvertisementList/>} />
        <Route path="/advertisement-add" element={<AdvertisementAdd/>} />
        <Route path="/advertisement-edit/:ID" element={<AdvertisementEdit/>} />
        <Route path="/advertisement-view/:ID" element={<AdvertisementView/>} />
        
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
