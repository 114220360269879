import React, { Component } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl,  mediaUrl} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };


class Edit extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const FontId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
			fontname:'',
			fontfamilyname:'',
			fonturl:'',
			font_file: [],
			status:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			c_font_id: FontId,
			Loading:false,
			fontweight:'',
			maintabFontSize : '',
			menuFontSize : '',
			primaryFontSize : '',
			letterSpacing : '',
			lineHeight : '',
			font_type:'google_font',
			old_image:[],
			file_name: '',
			file_preview: '',
			fontFile: '',
			file_fileExtension:'',
			old_font : '',
			varientCount : 0,
			varient_name : [],
			file_id: '',
			varient_id: '',
			varient_name_default : 'regular-400',
			selectedFileCount : 0,

			bookDetails: [
				{
				  index: Math.random(),
				  varient_name: "",
				  varient_id : "",
				  font_file: [],
				  
				}
			],
			variantFileData : [],
		};
		

		

		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
	    this.confirmPopup = this.confirmPopup.bind(this);
		//console.log(this.state.c_company_id+"-test");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Font Edit');
		this.newDataloader();   
    }
	newDataloader = (r=null) =>{
		axios.get(apiUrl+"font/listdetail?id="+this.state.c_font_id).then(res => {
			if(res.data.status === "success"){

				const formdata = res.data.fontlist;
				if(formdata.tf_status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
				}
				// this.setState({fontname:formdata.tf_name});							
				// this.setState({fonturl: formdata.tf_import_url});
				// this.setState({fontfamilyname: formdata.tf_font_family});

				this.setState({fontname:res.data.fontlist.tf_name,fontweight:res.data.fontlist.tf_fontweight,maintabFontSize:res.data.fontlist.tf_maintabfontsize,menuFontSize:res.data.fontlist.tf_menufontsize, primaryFontSize:res.data.fontlist.tf_primaryfontsize,fonturl: res.data.fontlist.tf_import_url,fontfamilyname: res.data.fontlist.tf_font_family,font_type:res.data.fontlist.tf_font_type,letterSpacing:res.data.fontlist.tf_letter_spacing,lineHeight:res.data.fontlist.tf_line_height, file_name:res.data.fontlist.tf_filename, old_font:res.data.fontlist.tf_filename});
				this.setState({variantFileData:this.variantViewFunc(res.data.fontVarientRecord)});
			}
		}); 
	}
	variantViewFunc = (variantRecords=null) => {
		var newthis = this;
        if (Object.keys(variantRecords).length > 0) {
		var listings = variantRecords.map((item, index) => {
			const fileRecords = (item.fileRecords ?  item.fileRecords : {});
			if (Object.keys(fileRecords).length > 0) {
				var files = Object.keys(fileRecords).map(function(k, ind){
					//console.log(fileRecords, k ,'--fileRecords');
					return (
						<div key={ind} className='font-file-show'>
							<div className='font-item'>
							<img
							className="" src={mediaUrl+'/'+'font1.png'}
							alt=''
							// style={{ width: '50px' }}
							/>		
							<p>{fileRecords[k].file_name
							}</p>
							<Link 
							onClick={newthis.confirmPopup.bind(this,fileRecords[k].id,fileRecords[k].varient_id)} 
							className="" title="delete"><i class="fa fa-times" aria-hidden="true"></i></Link>				
						</div>
						</div>
					);
				});
			
			} else {
				var files ='';
			}
			return (<div className="form-row" key={index}>				
				 		<div className="form-group">
				 			<h4>{item.varient_name}</h4>	
							{files}
				 		</div>					
				 	</div>)
			});
			return listings;
        } else {
          return <p></p>;
        }
      }

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					c_font_id : formPayload.c_font_id,
					font_name    : formPayload.fontname,
					fonturl    : formPayload.fonturl,
					font_family_name    : formPayload.fontfamilyname,
					selectedFile : formPayload.selectedFile,
					status       : status,
					fontweight : formPayload.fontweight,
					maintabFontSize : formPayload.maintabFontSize,
					menuFontSize : formPayload.menuFontSize,
					primaryFontSize : formPayload.primaryFontSize,
					letterSpacing : formPayload.letterSpacing,
					lineHeight : formPayload.lineHeight,
					font_type:formPayload.font_type,
					old_font:formPayload.old_font,
					bookDetails:JSON.stringify(formPayload.bookDetails),
				};
				let formData = new FormData();
				
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				//var filesdata = this.state.font_file;
				
				// for (var i in filesdata) {
				//  formData.append('font_file[]',filesdata[i])
				// }
				var bookDetails = this.state.bookDetails;
				for (var i in bookDetails) {
					if(bookDetails.length != 0){
						var fontfile = bookDetails[i]['font_file'+i];
						for(var j in fontfile){
							//console.log(bookDetails[j]['font_file'+i], '--formData2');
							formData.append('font_file'+i+'[]',fontfile[j])
						}				 
					}
				}		
				//axios.post(apiUrl+"font/edit",qs.stringify(postObject)).then(res => {
				axios.post(apiUrl+"font/edit",formData ).then(res => {
					if(res.data.status === "success"){
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/fonts');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {

		const {fontname,fontfamilyname,selectedFile,fonturl,fontweight,maintabFontSize,menuFontSize,primaryFontSize,font_type,font_file,letterSpacing,lineHeight, file_name, bookDetails} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!fontname) {
			errors++;
			$('.errorfont_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfont_name').html('');
		}
		
		if (font_type == 'google_font') {
			if (!fonturl) {
				errors++;
				$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorfonturl').html('');
			}
			// if (!fontweight) {
			// 	errors++;
			// 	$('.errorfontweight').html('<span class="errorspan">Please fill the field</span>');
			// }else{
			// 	$('.errorfontweight').html('');
			// }
		}else if(font_type == 'normal_font'){
			/*if(font_file.length==0){
				errors++;
				$('.errorfile').html('<span class="errorspan">Please upload files</span>');
			}else{
				$('.errorfile').html('');	
			}*/
			// if(!file_name){
			// 	errors++;
			// 	$('.errorfile').html('<span class="errorspan">Please upload files</span>');
			// }else{
			// 	$('.errorfile').html('');	
			// }
			// if(bookDetails.length != 0){
			// 	for (var i in bookDetails) {					
			// 		var fontfile = bookDetails[i]['font_file'+i];
			// 		//console.log(fontfile, '--fontfile');
			// 		if(fontfile != undefined){
			// 			if(fontfile.length==0){
			// 				errors++;
			// 				$('.errorfile'+i).html('<span class="errorspan">Please upload files</span>');
			// 			}else{
			// 				$('.errorfile'+i).html('');	
			// 			}		 					
			// 		} else{
			// 			$('.errorfile'+i).html('<span class="errorspan">Please upload files</span>');
			// 		}
			// 	}	
			// }

			
		}
		
		if (!fontfamilyname) {
			errors++;
			$('.errorfontfamilyname').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfontfamilyname').html('');
		}

		/*if (!fonturl) {
			errors++;
			$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfonturl').html('');
		}*/


		/*if (selectedFile === null || selectedFile === '') {
		$('.errorfile').html('<span class="errorspan">Please choose the font file</span>');
		errors++;
		}else if(selectedFile){
	    var filePath = selectedFile.name;
	    var allowedExtensions =  
                    /(\.ttf)$/i; 
        if(!allowedExtensions.exec(filePath)){
        	errors++;
		    $('.errorfile').html('<span class="errorspan">Please choose only ttf format</span>');
        }else{
            $('.errorfile').html('');
        } 
        }else{             
		$('.errorfile').html('');
		}*/

		if(errors>0) {
		
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		this.setState({
			file_name: file.name,
			file_preview: reader.result,
			fontFile: file,
			file_fileExtension:extension,
			
		},()=>{
			console.log(this.state.icon,'-icon')
		});
		};
		reader.readAsDataURL(file);
	}
	addMoreFunc = (e) => {
		return this.state.bookDetails.map((val, idx) => {
			let name = `font_file_${idx}`,
				varient = `varient_${idx}`,
				font_file = `font_file${idx}`,
				errorfile = `errorfile${idx}`
			return (
			  <div className="form-row" key={val.index}>
				<div className="form-left"  >
					<div className='form-group'>
					<label>Font Variant </label>
					<div className='display-flex align-items-center gap-5'>					
					<select name="varient_name" className="form-control" id={varient} data-id={idx} onChange={(e) => this.handleFileChange(e, idx)}>
						<option value="regular-400" className="fontVarientName" selected> Regular 400</option>
						<option value="medium-500" className="fontVarientName" > Medium 500</option>
						<option value="light-300" className="fontVarientName" > Light 300</option>
						<option value="bold-700" className="fontVarientName" > Bold 700</option>								
					</select>	
					{idx === 0 ? (
					<span className="add_font_varients"  onClick={() => this.addFunc()}><i class="fa fa-plus-circle fa-2x"></i></span> ) : 
					<span className="btn_remove" onClick={() => this.deleteFunc(val)} ><i class="fa fa-trash fa-2x"></i></span>
					}	
					</div>						
				</div>
				</div>
				<div className="form-right" >
				<div className="form-group">
					<label>Upload {this.state.fontname} font files (eot|ttf|woff|woff2) <span class="required" style={{ color: "red" }} > * </span> :</label>
					<div class="choose-file">
					<input type="file" name={font_file} 
					onChange={(e) => this.handleFileChange(e, idx)} 
					//onChange={this.onFileChange}
					data-id={idx}
					id={font_file} multiple   />
					</div> 	
					<p className={font_file}></p> 				
					<div className={errorfile}></div> 
					
				</div>	
				</div>				
			  </div>
			);
		  });
	}
	addFunc = e => {
		this.setState(prevState => ({
		  bookDetails: [
			...prevState.bookDetails,
			{
			  index: Math.random(),
			  varient_name: "",
			  varient_id : "",
			}
		  ]
		}));
	};
	deleteFunc(record) {
		this.setState({
		  bookDetails: this.state.bookDetails.filter(r => r !== record)
		});
	}
	handleFileChange = (e, idx) => {
		let	font_file = `font_file${idx}`;
		let bookDetails = [...this.state.bookDetails];
		bookDetails[e.target.dataset.id]['varient_id'] = idx;
		if (
		  ["varient_name"].includes(
			e.target.name
		  )
		) {
		  let bookDetails = [...this.state.bookDetails];
		  bookDetails[e.target.dataset.id][e.target.name] = e.target.value;
		} 
		else if(e.target.name == font_file){
			this.fileObj = [];
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))					
				this.imageArray.push(this.fileObj[0][i])
			}
			let bookDetails = [...this.state.bookDetails];
			//console.log(this.imageArray, idx, '--e.target.dataset.id');
			  bookDetails[e.target.dataset.id][font_file] = this.imageArray;
			  this.setState({ 'selectedFileCount': this.imageArray.length });
			  	if(this.imageArray.length > 1){
					var countMsg = this.imageArray.length+' files';
				} else if(this.imageArray.length == 1){
					var countMsg = this.imageArray.length+' file';
				} else{
					var countMsg = '';
				}
				$(`.font_file${idx}`).text(countMsg);
			this.imageArray = [];
			//this.setState({ font_file: this.imageArray })	
		}
		else {
			this.setState({ [e.target.name]: e.target.value });
		}
	}; 

	confirmPopup = (id, varient_id) => {
		
		this.setState({file_id: id,varient_id: varient_id })	
		$('.confirm-action').addClass('show');		
	  }
	  Confirmstatus = (confirmstatus) => {
		if(confirmstatus == 'yes'){
		  this.deletefontfile();
		  $('.confirm-action').removeClass('show');
		}else{
		  $('.confirm-action').removeClass('show');	
		}
		  
	  }
  
  
	  deletefontfile(){
		  var qs = require('qs');
		  var admin_id = localStorage.getItem("admin_id");
		  var postObject = {
			file_id  :this.state.file_id,
			varient_id  :this.state.varient_id,
			admin_id  : admin_id
		  };
  
		axios.post(apiUrl+"font/delete_font_file",qs.stringify(postObject)).then(res => {
	   
		  if(res.data.status === 'success'){
			$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
			setTimeout(
			function() {
			$('.success_message').html('');
			}
			.bind(this),
			3000
			);
  
			this.newDataloader()
			  }
		}); 
  }
  
  render() {
  	  const {selectedOption} = this.state;
		var	preview =  <img className="img_class" src={mediaUrl+'font1.png'} alt="" />;
		
    return (
      <div className="wrapper"> 
 		<div className="confirm-action">
			<p>Are you sure! you want to delete the file?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
	  <Header />
    <MenuSidebar currentpage="fonts" />  
	<div className="content">	
		<div className="content-wrapper">
				  <div className="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card font-block">
			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/fonts">Back</a>
			</div>
			<div className="title">
				<h4>Edit Font</h4>
			</div>
			 <form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			{/* <div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Font Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="fontname" onChange={this.handleInputChange} className="form-control" value={this.state.fontname} autoComplete="off" />
					<div className="errorfont_name"></div>
				</div>
			
				<div className="form-group">					
					<label>Font type<span class="required" style={{ color: "red" }} > * </span> :</label><br />

					<input type="radio" name='font_type' value="google_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'google_font') ? 'checked' : '' } /> 
					<span>Google Font</span>

					<input type="radio" name='font_type' value="normal_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'normal_font') ? 'checked' : '' } />
					<span>Normal Font</span>
					
					<div className="error_font_type"></div>
				</div>
				
				<div className={this.state.font_type == 'google_font'?"show-font-type":"hide-font-type"}>
								
					<div className="form-group">
						<label>Font Import url:</label>
						<textarea className="form-control" name="fonturl" onChange={this.handleInputChange}  value={this.state.fonturl} id="" rows="5"  autoComplete="off" />
						<div className="errorfonturl"></div>
					</div>
					<div className="form-group">
						<label>Font Weight :</label>
						<input type="text" name="fontweight" onChange={this.handleInputChange} className="form-control" value={this.state.fontweight} autoComplete="off" />
						<div className="errorfontweight"></div>
					</div>
				</div>				
				
				
				<div className="form-group">
					<label>Main Tab Font Size</label>
					<input type="text" name="maintabFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.maintabFontSize} autoComplete="off" />
					<div className="errormaintabFontSize"></div>
				</div>
				
				<div className="form-group">
					<label>Line Height</label>
					<input type="text" name="lineHeight" onChange={this.handleInputChange} className="form-control" value={this.state.lineHeight} autoComplete="off" />
					<div className="errorlineHeight"></div>
				</div>
				<div className={this.state.font_type == 'normal_font'?"show-font-type":"hide-font-type"} id="fontFile">		
						{this.state.variantFileData}
						{this.addMoreFunc()}							
				</div>
			</div>				
			<div className="form-left">
				<div className="form-group">
					<label>Exact Family Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="fontfamilyname" onChange={this.handleInputChange} className="form-control" value={this.state.fontfamilyname} autoComplete="off" />
					<div className="errorfontfamilyname"></div>
				</div>

				
				<div className="form-group">
					<label>Menu Font Size</label>
					<input type="text" name="menuFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.menuFontSize} autoComplete="off" />
					<div className="errormenuFontSize"></div>
				</div>
				<div className="form-group">
					<label>Primary Font Size</label>
					<input type="text" name="primaryFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.primaryFontSize} autoComplete="off" />
					<div className="errorprimaryFontSize"></div>
				</div>
				<div className="form-group">
					<label>Letter Spacing</label>
					<input type="text" name="letterSpacing" onChange={this.handleInputChange} className="form-control" value={this.state.letterSpacing} autoComplete="off" />
					<div className="errorletterSpacing"></div>
				</div>
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>	
			</div>	 */}
			<div className='form-row'>
				<div className='form-left'>
					<div className='form-group'>
					<label>Font Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="fontname" onChange={this.handleInputChange} className="form-control" value={this.state.fontname} autoComplete="off" />
					<div className="errorfont_name"></div>
					</div>
				</div>
				<div  className='form-right'>
				<div className="form-group">
					<label>Exact Family Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="fontfamilyname" onChange={this.handleInputChange} className="form-control" value={this.state.fontfamilyname} autoComplete="off" />
					<div className="errorfontfamilyname"></div>
				</div>
				</div>
			</div>
			<div className='form-row'>
				<div className='form-left'>
				<div className="form-group">					
					<label>Font type<span class="required" style={{ color: "red" }} > * </span> :</label><br />
					<div className='display-flex gap-5'>
					<div className='display-flex'>
					<input type="radio" name='font_type' value="google_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'google_font') ? 'checked' : '' } /> 
					<span>Google Font</span>
					</div>
					<div className='display-flex'>
					<input type="radio" name='font_type' value="normal_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'normal_font') ? 'checked' : '' } />
					<span>Normal Font</span>
					</div>
					</div>
					<div className="error_font_type"></div>
				</div>
				</div>
			</div>
			<div className='form-row'>
				<div className='form-left'>
					<div className='form-group'>
					<label>Main Tab Font Size</label>
					<input type="text" name="maintabFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.maintabFontSize} autoComplete="off" />
					<div className="errormaintabFontSize"></div>
					</div>
				</div>
				<div  className='form-right'>
				<div className="form-group">
				<label>Menu Font Size</label>
					<input type="text" name="menuFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.menuFontSize} autoComplete="off" />
					<div className="errormenuFontSize"></div>
				</div>
				</div>
			</div>
			<div className='form-row'>
				<div className='form-left'>
					<div className='form-group'>
					<label>Primary Font Size</label>
					<input type="text" name="primaryFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.primaryFontSize} autoComplete="off" />
					<div className="errorprimaryFontSize"></div>
					</div>
				</div>
				<div  className='form-right'>
				<div className="form-group">
				<label>Letter Spacing</label>
					<input type="text" name="letterSpacing" onChange={this.handleInputChange} className="form-control" value={this.state.letterSpacing} autoComplete="off" />
					<div className="errorletterSpacing"></div>
				</div>
				</div>
			</div>
			<div className='form-row'>
				<div className='form-left'>
					<div className='form-group'>
					<label>Line Height</label>
					<input type="text" name="lineHeight" onChange={this.handleInputChange} className="form-control" value={this.state.lineHeight} autoComplete="off" />
					<div className="errorlineHeight"></div>
					</div>
					
				</div>
				<div  className='form-right'>
				<div className="form-group">
				<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>
			<div className={this.state.font_type == 'normal_font'?"show-font-type":"hide-font-type"} id="fontFile">		
			
						{this.state.variantFileData}
						
						{this.addMoreFunc()}
												
				</div>
			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&  <span className="load-data"></span> }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>		 	
    </div>
    );
  }
}

export default withRouter(Edit);
{/* <div className={this.state.font_type == 'normal_font'?"show-font-type":"hide-font-type"}>
					<div className="form-group">
						<label>Font<span class="required" style={{ color: "red" }} > * </span> :</label>
						<input type="file" onChange={this.onChangeHandler} />
						{preview}
						{this.state.file_name} 
						<div className="errorfile"></div> 
					</div>
				</div>	 */}