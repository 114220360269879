import React, { Component } from 'react';
import Header from '../Layouts/Header';

import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import {apiUrl ,tapURL } from "../Config/Config";
import Axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    //const colorid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
    const companyid = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;
    // console.log(colorid,'colorid')
    this.state = {      
      companyid: companyid,
      tablist:'',
      tabgalleries: [],
    };

    var qs = require('qs');
    var postObject = {             
    id   :companyid
    };
   
    Axios.post(apiUrl+"tabmanagement/listdetail",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
        console.log( res.data.tablist)
          this.setState({ tablist: res.data.tablist});
          this.setState({ tabgalleries: res.data.tabimageslist }, () => {
            console.log(this.state.tabgalleries, 'productedit');
            // Render your galleries here using this.state.tabgalleries
        });
      }
    });  
  }

   
   componentDidMount() {
     document.title = PageTitle('Product View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.companylistview!==this.state.companylistview){
        if(Props.productlistview[0].status == "success"){
          this.setState({companylistview: Props.companylistview[0].companylistview[0]}) 
        }
      }

    
   }

   
  stripHtmlTags(html) {
    if (!html) {
      return '';
    }
    const regex = /(<([^>]+)>)/ig;
    return html.replace(regex, '');
  }
  
  render() {
   
    const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
 
      <Header />
      <MenuSidebar currentpage="Company" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="content-body">
            <div class="content-wrapper-before"></div>
            <div className="form-wrapper leaa-admin-head card">
                <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                <div className="title">
                  <h3>Tab Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Tab Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tablist.name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Content Type<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tablist.content_type}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.stripHtmlTags(this.state.tablist.short_description)}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                        <p>{this.stripHtmlTags(this.state.tablist.description)}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Button Text<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                        <p>{this.stripHtmlTags(this.state.tablist.button_name)}</p>
                        </div>
                      </div>
                       
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Icon<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                        <p><img className="img_class" src={`${tapURL}/${this.state.tablist.image}`} alt="" /></p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Galleries<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two gallery">
                          <div className='gallery-container'>
                          {this.state.tabgalleries.length > 0 ? (
                            this.state.tabgalleries.map((item) => (
                            <div className='gallery-inner' key={item.rehab_gallery_id}>
                                <img
                                width="100px"
                                height="100px"
                                src={tapURL + '/' + 'tabimages' + '/' + item.tab_image}
                                alt={`Image ${item.id}`}
                                />
                            </div>
                            ))
                            ) : (
                                <p>N/A</p>
                            )}
                            </div>
                        </div>
                      </div>
                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                        <p>{(this.state.tablist.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                      </div>
                      </div>
               
                    {/* <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div> */}
              </div>            
               
              
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));