import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyEditor from '../Membership/MyEditor';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			offer_title:'',
			title:'',
			description:'',
			content:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			selectedtype : '',
			selectedtypeValue : '',
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',
			categorynamelist: [],
			selectedTypeValue: '',
			showCategoryDropdown: false,
			selectedCategoryValue:'',
			button_name:'',
			tab_image:'',
			galleryimg: 'No',
			short_description: '',
			agreedescription:'',
			abdescriptions_name:'',
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
		this.removeImagebyindex = this.removeImagebyindex.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
		
    }
	handleTypeChange = selectedOption => {
		const selectedTypeValue = selectedOption.value;
		this.setState({ 
			selectedtype: selectedOption,
			selectedtypeValue: selectedOption.value,
			showCategoryDropdown: false,
			categorynamelist: [] 
		  });
		
    };  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }

  onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	const extension = file.name.split('.').pop();
	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file,
		fileExtension:extension,
		
	  });
	};
	reader.readAsDataURL(file);

	
  }
  uploadMultipleFiles = (e) =>{
	this.fileObj = [];

		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			//this.fileArray.push(this.fileObj[0][i]
			this.imageArray.push(this.fileObj[0][i])

		}
		
		this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
	}
	onEditorChange( evt ) {
		console.log(evt, 'CKE')
		setTimeout(function () {
		this.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}
	removeImagebyindex = (indexvalue) => {
		   
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
	   
		for (let i = 0; i < list.length; i++) {
		  this.imageArray.push(list[i]);
		  this.fileArray.push(URL.createObjectURL(list[i]));
		}
	  
		  this.setState({ tab_image: this.imageArray })	
  }
	handleFormSubmit = () => {
		if(this.validateForm()){ 
			this.setState({Loading:true});
			
			const formPayload = this.state;
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}

			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				offer_title: formPayload.offer_title,
				title : formPayload.title,
				description: formPayload.description,
				content_type: formPayload.selectedtypeValue,
				category:formPayload.selectedCategoryValue,
				status:  status,
				short_description: formPayload.short_description,
				
			};
			let formData = new FormData();
			for(let k in postObject) {
			formData.append(k, postObject[k]);
			}		
			
				
					var filesdata = this.state.tab_image;
						var galleryimg = this.state.galleryimg;
						if(galleryimg==2){
						for (var i in filesdata) {
							formData.append('multiple_images[]',filesdata[i])
						}
					}       
			axios.post(apiUrl+"cmsmanagement/add",formData ).then(res => {
				if(res.data.status === "success"){	
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								
								$('.success_message').html('');
								this.props.navigate('/cms');
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
}

validateForm() {
	//const {admin_company_name,admin_fname} = this.state;
	const {offer_title,short_description,description,selectedtypeValue,image ,tabdescriptions_name,selectedtype,button_name} = this.state;
	let errors = {};
	  let formIsValid = true;
	  
	if (!offer_title) {
		formIsValid = false;
		$('.erroroffer_title').html('<span class="errorspan">Please fill the  page title</span>');
	}else if(offer_title){
		if (/^[a-zA-Z\s]*$/.test(offer_title)) {
		$('.erroroffer_title').html('');
		}else{
			formIsValid = false;
			$('.erroroffer_title').html('Please enter valid  page title');
		}
	}
	
	if (!short_description) {
		formIsValid = false;
		$('.errorshort_description').html('<span class="errorspan">Please fill the short description</span>');
	}else if(short_description){
		$('.errorshort_description').html('');
	}

	
	if (!description) {
		formIsValid = false;
		$('.errordescription').html('<span class="errorspan">Please fill the description</span>');
	}else if(description){
		$('.errordescription').html('');
	}
	
	
	
	
	

	this.setState({
		errors: errors
	});
	return formIsValid;
}

handleClickSelecetCategory = (event) => {
    const selectedValue = event.target.value; // Get the selected value from the event
    this.setState({ selectedCategoryValue: selectedValue }); // Set the selected value to the component's state
  }
  render() {

	const {selectedOption,selectedtype ,showCategoryDropdown} = this.state;
	var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	const navigate = this.props.navigate;
	let imageslist = [];

	  let indexlength =  imageslist.length;

	const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
		</li>
	))
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="cms" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="content-wrapper">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div>
			<div className="title">
				<h4>Add Offer</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Page Title<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="offer_title" onChange={this.handleInputChange} className="form-control" value={this.state.offer_title}/>
					<div className="erroroffer_title"></div>
				</div>
				
				<div className="form-group">
						<label>Short Description<span class="required" style={{ color: "red" }} >* </span> :</label>
						<MyEditor  onEditorChange={this.onEditorChange}></MyEditor>
						<div className="errorshort_description"></div>	
				</div>
				
				
			   <div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">			
							{gallerylocal}
					</ul>
				</div> 
			</div>
                  
			<div className="form-right">
				
					<div className="form-group">
						<label>Description<span class="required" style={{ color: "red" }} >*</span>:</label>
						
							<CKEditor
								editor={ ClassicEditor }
								data=""
								onReady={ editor => {
									
								} }
								onChange={ ( event, editor ) => {
									const data = editor.getData();
									this.setState({description: data});
									
								} }
							/>
						<div className="errordescription"></div>  
						</div>
					<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >* </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />
	           		</div> 
				

				
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));