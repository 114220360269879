import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
const withRouter = WrappedComponent => props => {
  const params = useParams();
  const  navigate = useNavigate();
  // etc... other react-router-dom v6 hooks
  
  return (
    <WrappedComponent
    {...props}
    params={params}
    navigate ={navigate}
    // etc...
    />
  );
  };	
class View extends Component {
  
  constructor(props)
  {
    super(props); 
   
    const locationid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
    this.state = {      
      locationid: locationid,
      locationlistdata:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :locationid
    };

    axios.post(apiUrl+"location/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ locationlistdata: res.data.locationlistview[0]});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Location View');
   }
  render() {
    const navigate = this.props.navigate;
  if(this.state.locationlistdata!==undefined){
    var locationlistdata = this.state.locationlistdata;
  }
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Location" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="content-wrapper back-btn">
                    <a onClick={() => navigate(-1)}>Back</a>    
                </div>
                <div className="title">
                  <h3>Location</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Location Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{locationlistdata.location}</p>
                        </div>
                      </div>

                     
                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Location Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(locationlistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                 

                      </div>
                      </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}


export default (withRouter(View));