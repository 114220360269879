import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";
import MyEditor from '../Membership/MyEditor';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';


// import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

/* import { GET_TEMPLATEADD }  from '../../actions';  */
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			email_name:'',
			email_content:'',	
			Loading:false,
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
	   this.onEditorChange = this.onEditorChange.bind( this );

    }
	

   handleInputChange(event) {

      const {name, value} = event.target;    
      this.setState({
          [name]: value
        });
    }

    // onEditorChange( evt ) {
    // 	console.log(evt.editor.getData())
    // 	var callfunc = this;
    // 	setTimeout(function () {
	// 	callfunc.setState( {
    //         email_content: evt.editor.getData()
    //     } );
	// 	}, 1000);
     
    // }
	onEditorChange( evt ) {
		
		console.log(evt, 'CKE')
	   // this.setState({description: evt
	   // } );
	   var callfunc = this;
	   setTimeout(function () {
	   this.setState( {
		email_content: evt
	   } );
	   }.bind(this),1000);
	
   }

	componentDidMount() {
		document.title = PageTitle('Template Add');		
    }



	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});


				const formPayload = this.state;
				var qs = require('qs');
			
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					email_name   : formPayload.email_name,
					email_content: formPayload.email_content,
				};
				
				// this.props.getTemplateAdd(qs.stringify(postObject));
				axios.post(apiUrl+"template/add",qs.stringify(postObject)).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   
						   setTimeout(
						   function() {
							
							   $('.success_message').html('');
									this.props.navigate('/templates');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {

		const {email_name,email_content} = this.state;

		let errors = 0;
	
		if (!email_name) {
			errors++;
			$('.erroremail_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(email_name){
			$('.erroremail_name').html('');
		}
		
		if (!email_content) {
			errors++;
			$('.erroremail_content').html('<span class="errorspan">Please fill the field</span>');
		}else if(email_content){
			$('.erroremail_content').html('');
		}



		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

    	 	if(Props.templateadd !== this.props.templateadd){
    		if(Object.keys(Props.templateadd).length > 0){
				this.setState({Loading:false});
					if(Props.templateadd[0].status === "success"){
		            	$('.success_message').html('<div class="status_sucess"><h3>'+Props.templateadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							this.setState({email_name:'',email_content:''})
							$('.success_message').html('');
							
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.templateadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
      }

  render() {
	const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
	  <Header />
      <MenuSidebar currentpage="templates" />  
	 <div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="content-wrapper">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div>
			<div className="title">
				<h4>Add Template</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row mail-template-sec">
			<div className="form-left mail-template-inner">
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Template Name<span class="required" style={{ color: "red" }} >* </span></label>
				    <input type="text" name="email_name" onChange={this.handleInputChange} className="form-control" value={this.state.email_name} autoComplete="off" />
					<div className="erroremail_name"></div>
				</div>
				</div>
				<div className="form-left">
				<div className="form-group">
					<label>Template content<span class="required" style={{ color: "red" }} >* </span></label>
					<MyEditor  onEditorChange={this.onEditorChange}></MyEditor>
					{/* <CKEditor
                    editor={ ClassicEditor }
                    data="<p>Hello from CKEditor&nbsp;5!</p>"
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                /> */}
					<div className="erroremail_content"></div>
				</div>
				</div>
				</div>
				
			</div>				

			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default withRouter(Add);