import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Select from 'react-select';

/* import { GET_VIEWappointmentlist } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const appointmentid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      appointmentid: appointmentid,
      appointmentlistview:'',
      selectedrehabOption:'',
			selectedrehabvalue:'',
      rehablist:'',
     
    };

    
  }

   
   componentDidMount() {
     document.title = PageTitle('User View');
     axios.get(apiUrl+"Appointment/apptlistdetails?id="+this.state.appointmentid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.appointmentlist;
			// if(formdata.app_status === 'A'){
			// 	this.setState({selectedOption:{value: 'active', label: 'Active'}});
			// }else{
			// 	this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			// }
			this.setState({appointmentlistview:res.data.apptlist[0]});
					console.log(res.data.apptlist[0],'this is testing for thsi')
			}
			
		}); 
     var qs = require('qs');
		// var postObject = {
		// 	admin_id : localStorage.getItem("admin_id"),
		// }
		// let rehablists = [];
		// axios.post(apiUrl+"rehab/rehablist",qs.stringify(postObject)).then(res => {
		// 	if(res.data.status == "success"){
		// 		let i=0;
		// 		let rehablist = res.data.rehablist;
				
		// 		for(i; i<rehablist.length; i++){
		// 			rehablists[i] = { value: rehablist[i]['rehab_id'], label: rehablist[i]['center_name'] };
		// 		}
		// 		this.setState({rehablist:rehablists})
		// 	}       
		// });
   }

   handlerehabChange = selectedrehabOption => {
    this.setState({selectedrehabOption:selectedrehabOption})
    this.setState({  selectedrehabvalue : selectedrehabOption.value});
};
reassignappointment(){
  
  var qs = require('qs');
  var postObject = {
    admin_id : localStorage.getItem("admin_id"),
    selected_rehab  :this.state.selectedrehabvalue,
    appointmentid  :this.state.appointmentid,
       status: 'AS'
  };
  axios.post(apiUrl+"appointment/change_status",qs.stringify(postObject)).then(res => {      
    if(res.data.status === 'success'){
      
        $('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
        setTimeout(
          function() {
         
            $('.success_message').html('');
            this.props.navigate('/appointments');
          }
          .bind(this),
          3000
          );
          
    }
  }); 
}
validateForm() {
const {selectedrehabvalue} = this.state;
let errors = 0;
if (!selectedrehabvalue) {
errors++;
$('.errorrehab').html('<span class="errorspan">Please select the field</span>');
}else if(selectedrehabvalue){
$('.errorrehab').html('');
}
if(errors>0) {
setTimeout(function () {
scrollToTopValidate();
}, 100);
return false;
}
else {
return true;
}

}
confirmPopup = (id, status) => {
if(this.validateForm()){
$('.confirm-action').addClass('show');	
}	
}

Confirmstatus = (confirmstatus) => {
if(confirmstatus == 'yes'){
this.reassignappointment();
$('.confirm-action').removeClass('show');
}else{
$('.confirm-action').removeClass('show');	
}

}
  render() {
    
  	const {rehablist,selectedrehabOption} = this.state;
    const navigate = this.props.navigate;
    //var medical_details = ((this.state.appointmentlistview.medical_details === null)||((this.state.appointmentlistview.medical_details === ''))) ?  this.state.appointmentlistview.medical_details = 'N/A' : this.state.appointmentlistview.medical_details;

    
    // if(this.state.appointmentlistview.app_status == 'A'){
    //   var status = 'Active';
    // }else{
    //     var status = 'Inactive';
    // }
    if(this.state.appointmentlistview.gender == 'M'){
      var gender = 'Male';
    }else if(this.state.appointmentlistview.gender == 'F'){
      var gender = 'Female';
    }else{
      var gender = 'Transgender';
    }
    const date = new Date(this.state.appointmentlistview.appintment_date);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedTime = `${this.state.appointmentlistview.hours}:${this.state.appointmentlistview.minutes} ${this.state.appointmentlistview.meridiam}`;

    const formattedDateTime = `${month} ${day} ${year} ${formattedTime}`
    return (
      <div className="wrapper"> 
  <div className="confirm-action">
			<p>Are you sure! you want to assign this appointment to other rehab?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="appointments" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="content-wrapper">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
               <div className="success_message"></div>
                <div className="title">
                  <h3>Appointment Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Appt ID<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.appointment_code}</p>
                          </div>
                        </div>
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Patient Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.patient_name}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Contact<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.user_contact}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.user_email}</p>
                          </div>
                        </div>
                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Gender<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{gender}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Purpose of Appt<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.purpose_visit!=''?this.state.appointmentlistview.purpose_visit:'N/A'}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Pickup location<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.pickup_location!=''?this.state.appointmentlistview.pickup_location:'N/A'}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                           {this.state.appointmentlistview.provider_charge_type === "Cash only"? (
                              <p><b>Non-Insurance Payment<span>:</span> </b></p>
                            ) : (
                              <p><b>Insurance<span>:</span> </b></p>
                            )}
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.insurance_name!=''?this.state.appointmentlistview.insurance_name:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Booking type<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.book_options_type!=''?this.state.appointmentlistview.book_options_type:'N/A'}</p>
                          </div>
                        </div>
                        {this.state.appointmentlistview.paynotes && (
                          <div className="view-details">
                              <div className="view-details-one">
                                <p><b>Comments<span>:</span> </b></p>
                              </div>
                            <div className="view-details-two">
                              <p>{this.state.appointmentlistview.paynotes}</p>
                            </div>
                          </div>
                        )}
                          {/* {insurance[0].provider_charge_type === "Cash only"? (
                          <h2 className="title">Non-Insurance Payment</h2>
                        ) : (
                          <h2 className="title">Insurance</h2>
                        )} */}
                        {this.state.appointmentlistview.consultationfees && (
                          <div className="view-details">
                              <div className="view-details-one">
                                <p><b>Consultation fee<span>:</span> </b></p>
                              </div>
                            <div className="view-details-two">
                              <p>${this.state.appointmentlistview.consultationfees}</p>
                            </div>
                          </div>
                        )}
                        
                        {this.state.appointmentlistview.appointment_amount &&  (
                          <div className="view-details">
                              <div className="view-details-one">
                                <p><b>Appointment fee<span>:</span> </b></p>
                              </div>
                            <div className="view-details-two">
                              <p>${this.state.appointmentlistview.appointment_amount}</p>
                            </div>
                          </div>
                        )}
                        {this.state.appointmentlistview.appointment_amount && this.state.appointmentlistview.consultationfees &&  (
                          <div className="view-details">
                              <div className="view-details-one">
                                <p><b>Total<span>:</span> </b></p>
                              </div>
                            <div className="view-details-two">
                              <p>${parseInt(this.state.appointmentlistview.consultationfees) + parseInt(this.state.appointmentlistview.appointment_amount)}</p>
                            </div>
                          </div>
                        )}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.status}</p>
                          </div>
                        </div>


                    </div>
                </div>
                 
                  
                <div className="title">
                  <h3>Provider Details</h3>
                </div>
                <div className="form-row">
                <div className="details_left">
                <div className="view-details">
                        <div className="view-details-one">
                              <p><b>Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>Dr.{this.state.appointmentlistview.name!=''?this.state.appointmentlistview.name:'N/A'} ({this.state.appointmentlistview.degree_name})</p>
                          </div>
                </div>
                <div className="view-details">
                    <div className="view-details-one">
                        <p><b>Specialties<span>:</span> </b></p>
                    </div>
                    <div className="view-details-two">
                  
                    <p>{this.state.appointmentlistview.specialty_name!=''?this.state.appointmentlistview.specialty_name:'N/A'}</p>
                    </div>
                  </div>
                <div className="view-details">
                        <div className="view-details-one">
                              <p><b>Contact<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.provider_contact!=''?this.state.appointmentlistview.provider_contact:'N/A'} </p>
                          </div>
                </div> 
                <div className="view-details">
                        <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.provider_email!=''?this.state.appointmentlistview.provider_email:'N/A'} </p>
                          </div>
                </div> 
                        
                        
                  
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Location<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.location!=''?this.state.appointmentlistview.location:'N/A'}</p>

                          </div>
                        </div>
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Country<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.country!=''?this.state.appointmentlistview.country:'N/A'}</p>
                          </div>
                        </div> */}
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Zip Code<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                        
                          <p>{this.state.appointmentlistview.pincode!=null?this.state.appointmentlistview.pincode:'N/A'}</p>

                          </div>
                        </div> */}
                        </div>
                        </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);
