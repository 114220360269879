import React,{Component} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Logout extends Component {

      constructor(props) {
        super(props); 
        this.state = {
        
          };
          console.log('123344')
          props.navigate('/');
    }

    componentDidMount(){
      console.log('12334')
      this.props.navigate('/');
    }

    getunsettoken(){
          /*console.log(localStorage.getItem("admin_id"))
          if(localStorage.getItem("admin_id") == 'undefined' && localStorage. getItem('admin_id') == '' && localStorage. getItem('admin_id') == null){
          console.log('123')
          this.props.history.push('/');
          }*/
    }
    render() {
      return (
        <></>
       /*  <h4 className="loading-text">
          Logging out...
        </h4> */
      );
    }
  }
 
export default withRouter(Logout);