import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			amenityname:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active'	,
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',		
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }

	componentDidMount() {
	}

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}
	};

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	const extension = file.name.split('.').pop();
	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file,
		fileExtension:extension,
		
	  });
	};
	reader.readAsDataURL(file);

	
  }
  handleSubmit = () => { 
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status = '';


	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		amenityname : formdata.amenityname,
		image : formdata.image,
		status: status
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"Amenities/add",formData).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html(this.props.navigate('/amenities'));
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }

  validateForm() {
	const {amenityname,image} = this.state;

	let errors = {};
	  let formIsValid = true;

	
	if (!amenityname) {
		formIsValid = false;
		$('.erroramenityname').html('<span class="errorspan">Please fill the field</span>');
	}else if(amenityname){
		if (/^[a-zA-Z\s]*$/.test(amenityname)) {
		$('.erroramenityname').html('');
		}else{
			formIsValid = false;
			$('.erroramenityname').html('Please enter valid amenity name');
		}
	}
	if (!image) {
		formIsValid = false;
		$('.errorfile').html('<span class="errorspan">Please fill the feild</span>');
			}else{
				$('.errorfile').html('');
			}
			if (image) {		
	if (!image.name.match(/\.(jpg|jpeg|png|svg)$/)) {
		formIsValid = false;
			 $('.errorfile').html('<span class="errorspan">Please select valid type</span>');
		   
	  }else{
		$('.errorfile').html('');
	  }
	}
	this.setState({
		errors: errors
	});
	return formIsValid;
}



  render() {
	const navigate = this.props.navigate;
	const {selectedOption} = this.state;
	var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;

	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="amenities" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="content-wrapper">
				<a onClick={() => navigate(-1)}>Back</a>   
			</div>
			<div className="title">
				<h4>Add Amenity</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="amenityname" onChange={this.handleInputChange} className="form-control" value={this.state.amenityname}/>
					<div className="erroramenityname"></div>
				</div>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 
			</div>
                  
			<div className="form-right">
			<div className="form-group">
					<label>Image (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} > * </span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorfile"></div>  
				</div>
				
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));