//  /*local */ 
// export const apiUrl      = "http://localhost/rxpanel/api/";
// export const baseUrl      = "http://localhost/rxpanel";
// export const mediaUrl      = "http://localhost/rxpanel/media/";
// export const mediaUrlImage      = "http://localhost/rxpanel/media/product";
// export const sampleFileUrl      = "http://localhost/rxpanel/media/sample_file/";
// export const cmscontentURL       = "http://localhost/rxpanel/media/cmscontent";
// export const categoryURL       = "http://localhost/rxpanel/media/category";
// export const tapURL       = "http://localhost/rxpanel/media/tabmanagement";
// export const specURL       = "http://localhost/rxpanel/media/specialties"; 
// export const insURL       = "http://localhost/rxpanel/media/insurance"; 
// export const providerURL       = "http://localhost/rxpanel/media/provider"; 
// export const amenityURL       = "http://localhost/rxpanel/media/amenities"; 

/*Live */


export const apiUrl      = "https://admin.rx.one/rxone/api/";
export const baseUrl      = "https://admin.rx.one/rxone";
export const mediaUrl      = "https://admin.rx.one/rxone/media";
export const sampleFileUrl      = "https://admin.rx.one/rxone/media/sample_file/";
export const cmscontentURL       = "https://admin.rx.one/rxone/media/cmscontent"; 
export const categoryURL       = "http://admin.rx.one/rxone/media/category";
export const tapURL       = "http://admin.rx.one/rxone/media/tabmanagement";
export const cmsURL       = "http://admin.rx.one/rxone/media/cmsmanagement";
export const providerURL       = "http://admin.rx.one/rxone/media/provider"; 
export const amenityURL       = "http://admin.rx.one/rxone/media/amenities"; 
export const insURL       = "http://admin.rx.one/rxone/media/insurance";