import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
/* import { GET_DASHBOARDLIST  } from '../../actions'; */

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      dashboardlist:'',
      totalRecordStore:0,
      totalRecordProducts:0,
      totalRecordCompanies:0,
      totalRecordOrders:0,
      totalRecordProductCategory: 5,
      totalRecordProvider: '',
      totalstore:0,
      totalRecordspecialties:0,
      totalRecordinsurance:0,
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };

    axios.post(apiUrl+"dashboard/list",qs.stringify(postobject)).then(res => {
      console.log(res.data,'testingdashborduse')
      if(res.data.status === 'success'){
        this.setState({ 
          totalRecordUsers : res.data.records_count_users,
          totalRecordCompanies : res.data.records_count_companies,
          totalRecordProducts : res.data.records_count_products,
          totalRecordOrders: res.data.records_count_product_history,
          totalRecordProvider: res.data.records_count_provider,
          totalRecordProductCategory:res.data.records_count_product_category,
          totalRecordspecialties:res.data.records_count_specialties,
          totalRecordinsurance:res.data.records_count_insurance,


          

          
          });
      }
    }); 

    }

   
    componentDidMount() {
   }




  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="dashboard" />
      
      <div className="content"> 
        <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
              <div className="car-listing-wrap dashboard-wrap leaa-admin-head card">

                  <div className="title">
                    <h3>Dashboard</h3>
                  </div>

                  <div className="form-row first">

                        <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge">{this.state.totalRecordUsers}</h4>
                            </div>
                              <a  href="/user" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Users <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                       </a>
                    </div>
                          </div>

                        {localStorage.getItem('admin_id') === '1' ?  <div className="form-right">
                            <div className="card pull-up border-top-info-red border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge-red card-title-red ">{this.state.totalRecordCompanies}</h4>
                            </div>
                              <a href="/company" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Companies<i className="ft-briefcase float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                       </a>
                    </div>
                          </div>: ''}
                    </div> 

                    <div className="form-row ">
            					<div className="form-left">
                      <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordProvider}</h4>
                             </div>
                              <a href="/provider" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Providers <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					</div>
                     
            					  <div className="form-right">
                         <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordProducts}</h4>
                             </div>
                              <a href="/product" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Products <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div>  

                        <div className="form-right">
                         
            					  </div>

                        <div className="form-left">
                        
            					  </div>
            				</div>

                    {/* -------------- */}
                   <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-blue border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-blue">{this.state.totalRecordspecialties}</h4>
                            </div>
                              <a href="Specialties" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Specialties<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-black border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-black">{this.state.totalRecordinsurance}</h4>
                             </div>
                              <a href="Insurance" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Insurance <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div> 
                    {/* <div className="form-row ">
            					<div className="form-left">
                        <div className="card pull-up border-top-info-orange border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-orange">{this.state.totalRecordProductCateogory}</h4>
                            </div>
                              <a href="category" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Product Category<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                    </div> */}

                    {/* -------------------- */}
                      
                </div>
          </div>  
      </div>
      
    </div>
    );
  }
}


export default List;