
import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";

import MyEditor from './MyEditor';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			m_title:'',
			description:'',
			amount:'',
			period:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedPeriodOption: "",
			selectedPlanOption: "",
			selectedvalue:'active',
			selectedPeriodvalue:'',
			selectedPlanvalue:'',
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }
	  
	onEditorChange( evt ) {
		
    	 console.log(evt, 'CKE')
		// this.setState({description: evt
		// } );
    	var callfunc = this;
    	setTimeout(function () {
		this.setState( {
            description: evt
        } );
		}.bind(this),1000);
     
    }
	
	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'period'){
			this.setState({selectedPeriodOption:selOption})
			this.setState({  selectedPeriodvalue : selOption.value});
		}else if(r == 'plan'){			
			this.setState({selectedPlanOption:selOption})
			this.setState({  selectedPlanvalue : selOption.value});
		}
		console.log(selOption, 'selopt')
		console.log(r, 'r')
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		console.log(name + ' ~ ' + value, 'name')

  }
  onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file
	  });
	};
	reader.readAsDataURL(file);
  }

  handleSubmit = () => {
	if(this.validateForm()){ 
		this.setState({Loading:true});
		const formdata = this.state;
		var qs = require('qs');
		var status = '';
		var period = '';
		var plan = '';
		if(formdata.selectedvalue === ''){
		status =formdata.selectedOption.value;
		}else{
		status = formdata.selectedvalue;
		}

		if(formdata.selectedPeriodvalue === '' && formdata.selectedPeriodvalue!== 0){
			period =formdata.selectedPeriodOption.value;
		}else{
			period = formdata.selectedPeriodvalue;
		}

		if(formdata.selectedPlanvalue === '' && formdata.selectedPlanvalue!== 0){
			plan =formdata.selectedPlanOption.value;
		}else{
			plan = formdata.selectedPlanvalue;
		}

		var postObject ={
			admin_id : localStorage.getItem("admin_id"),
			title: formdata.m_title,
			description: formdata.description,
			amount: formdata.amount,
			period: period,
			plan: plan,
			status:  status,
		}
		

		// let formData = new FormData();
		// for(let k in postObject) {
		// 	formData.append(k, postObject[k]);
		// }
		// console.log(formData, 'formDaTa');
		axios.post(apiUrl+"membership/add",qs.stringify(postObject)).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html(this.props.navigate('/membership'));
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
		});

	}
		
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {m_title,description,amount,period,selectedPeriodOption,selectedPlanOption} = this.state;
		let errors = {};
      	let formIsValid = true;
		  const nameRegex = /^[A-Za-z\s.]+$/;
		
		if (!m_title) {
			errors++;
			$('.errortitle').html('<span class="errorspan">Please fill the field</span>');
		}else if(m_title){
			if (!nameRegex.test(m_title)) {
				errors++;
			$('.errortitle').html('<span class="errorspan">Please enter the valid membership</span>');
			}else{
				$('.errortitle').html('');
			}
			
		}
		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		
		if (!amount) {
			formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
		}else if(amount){
			if (/^[0-9]*\.?[0-9]*$/.test(amount)) {
				$('.erroramount').html('');
			  }else{
				formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please enter the valid amount</span>');
			  }

			
		}

		

		if (!selectedPeriodOption) {
			formIsValid = false;
			$('.errorperiod').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedPeriodOption){
			$('.errorperiod').html('');
		}

		if (!selectedPlanOption) {
			formIsValid = false;
			$('.errorplan').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedPlanOption){
			$('.errorplan').html('');
		}


		this.setState({
			errors: errors
		});
		return formIsValid;
    }



  render() {
	const navigate = this.props.navigate;
	let preview = '';
	const {selectedOption,selectedPeriodOption,selectedPlanOption} = this.state;

	var current = this;	

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="membership" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="content-wrapper">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div>
			<div className="title">
				<h4>Add Membership</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">					
					<label>Membership Category<span class="required" style={{ color: "red" }} >* </span></label>
						   <Select 
						   value={selectedPlanOption?selectedPlanOption:""}
                            options={lang.common.mem_plan_option} 
                            onChange={this.handleChange.bind(this, 'plan')}
                            />
							<div className="errorplan"></div>
	           </div> 
				

				<div className="form-group">
					<label> Amount ($)<span class="required" style={{ color: "red" }} >* </span> </label>
				    <input type="text"  name="amount" onChange={this.handleInputChange} className="form-control" value={this.state.amount} />
					<div className="erroramount"></div>
				</div>

				
			

			   <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange.bind(this, 'status')}
                            />
	           	</div> 
				

			</div>
                  
			<div className="form-right">
			<div className="form-group">
					<label>Membership Name<span class="required" style={{ color: "red" }} >* </span></label>
				    <input type="text" name="m_title" onChange={this.handleInputChange} className="form-control" value={this.state.m_title}/>
					<div className="errortitle"></div>
				</div>
				<div className="form-group">					
					<label>Period<span class="required" style={{ color: "red" }} >* </span></label>
						   <Select 
						   value={selectedPeriodOption?selectedPeriodOption:""}
                            options={lang.common.period_option} 
                            onChange={this.handleChange.bind(this, 'period')}
                            />
							<div className="errorperiod"></div>
	           </div> 
				<div className="form-group">					
					<label>Description</label>
						{/* <textarea style={{minHeight: "180px"}} type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}
						<MyEditor  onEditorChange={this.onEditorChange}></MyEditor>
					
					
					  {/* <div id="editor" onChange={this.onEditorChange}></div> */}
					<div className="errordescription"></div>
				</div>
				
				
			   

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));