import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyEditor from '../Membership/MyEditor';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			tab_name:'',
			title:'',
			description:'',
			content:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			selectedtype : '',
			selectedtypeValue : '',
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',
			categorynamelist: [],
			selectedTypeValue: '',
			showCategoryDropdown: false,
			selectedCategoryValue:'',
			button_name:'',
			tab_image:'',
			galleryimg: 'No',
			short_description: '',
			agreedescription:'',
			abdescriptions_name:'',
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
		this.removeImagebyindex = this.removeImagebyindex.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
		
    }
	handleTypeChange = selectedOption => {
		const selectedTypeValue = selectedOption.value;
		this.setState({ 
			selectedtype: selectedOption,
			selectedtypeValue: selectedOption.value,
			showCategoryDropdown: false,
			categorynamelist: [] // Clear categorynamelist for non-product options
		  });
		// if (selectedTypeValue === 'product') {
		// 	var admin_id = localStorage.getItem('admin_id');
		// 	axios.get(apiUrl+'product/getcategorynamealllist?admin_id='+admin_id+'&type=addtab')
		// 	.then(res => {
		// 		if(res.data.status == 'success'){
				
		// 			this.setState({
		// 				selectedtype: selectedOption,
		// 				selectedtypeValue: selectedOption.value,
		// 				categorynamelist: [{ value: '', label: 'Select Category' }].concat(res.data.categorynamelist),
		// 				showCategoryDropdown: true // Show the Category dropdown only if 'product' is selected
		// 			  });
		// 			 // Show the Category dropdown upon successful API response
       
		// 		}else{
		// 			this.setState({ showCategoryDropdown: false,
		// 				categorynamelist: []
		// 			 });
		// 		}
		// 	});
		
		//   } else {
			
		// 	this.setState({ 
		// 		selectedtype: selectedOption,
		// 		selectedtypeValue: selectedOption.value,
		// 		showCategoryDropdown: false,
		// 		categorynamelist: [] // Clear categorynamelist for non-product options
		// 	  });
		
		//   }
    };  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }

  onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	const extension = file.name.split('.').pop();
	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file,
		fileExtension:extension,
		
	  });
	};
	reader.readAsDataURL(file);

	
  }
  uploadMultipleFiles = (e) =>{
	this.fileObj = [];

		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			//this.fileArray.push(this.fileObj[0][i]
			this.imageArray.push(this.fileObj[0][i])

		}
		
		this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
	}
	onEditorChange( evt ) {
		console.log(evt, 'CKE')
		setTimeout(function () {
		this.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}
	removeImagebyindex = (indexvalue) => {
		   
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
	   
		for (let i = 0; i < list.length; i++) {
		  this.imageArray.push(list[i]);
		  this.fileArray.push(URL.createObjectURL(list[i]));
		}
	  
		  this.setState({ tab_image: this.imageArray })	
  }
	handleFormSubmit = () => {
		if(this.validateForm()){ 
			this.setState({Loading:true});
			
			const formPayload = this.state;
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}

			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				tab_name: formPayload.tab_name,
				title : formPayload.title,
				description: formPayload.description,
				content_type: formPayload.selectedtypeValue,
				image : formPayload.image,
				category:formPayload.selectedCategoryValue,
				status:  status,
				short_description: formPayload.short_description,
				agreedescription: formPayload.agreedescription,
				tabdescriptions_name: formPayload.tabdescriptions_name,
				button_name: formPayload.button_name,
				
				
			
			};
			let formData = new FormData();
			for(let k in postObject) {
			formData.append(k, postObject[k]);
			}		
			
				
					var filesdata = this.state.tab_image;
						var galleryimg = this.state.galleryimg;
						if(galleryimg==2){
						for (var i in filesdata) {
							formData.append('multiple_images[]',filesdata[i])
						}
					}       
			axios.post(apiUrl+"tabmanagement/add",formData ).then(res => {
				if(res.data.status === "success"){	
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								
								$('.success_message').html('');
								this.props.navigate('/tabmanagement');
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
}

validateForm() {
	//const {admin_company_name,admin_fname} = this.state;
	const {tab_name,short_description,description,selectedtypeValue,image ,tabdescriptions_name,selectedtype,button_name} = this.state;
	let errors = {};
	  let formIsValid = true;
	  
	if (!tab_name) {
		formIsValid = false;
		$('.errortab_name').html('<span class="errorspan">Please fill the  tab name</span>');
	}else if(tab_name){
		if (/^[a-zA-Z\s]*$/.test(tab_name)) {
		$('.errortab_name').html('');
		}else{
			formIsValid = false;
			$('.errortab_name').html('Please enter valid tab name');
		}
	}
	
	if (!short_description) {
		formIsValid = false;
		$('.errorshort_description').html('<span class="errorspan">Please fill the short description</span>');
	}else if(short_description){
		$('.errorshort_description').html('');
	}

	
	if (!description) {
		formIsValid = false;
		$('.errordescription').html('<span class="errorspan">Please fill the description</span>');
	}else if(description){
		$('.errordescription').html('');
	}
	if (!button_name) {
		formIsValid = false;
		$('.errorbutton_name').html('<span class="errorspan">Please fill the button name</span>');
	}else if(button_name){
		$('.errorbutton_name').html('');
	}
	if (!tabdescriptions_name) {
		formIsValid = false;
		$('.errortabdescriptions_name').html('<span class="errorspan">Please fill the tab descriptions </span>');
	}else if(tabdescriptions_name){
		$('.errortabdescriptions_name').html('');
	}
	
	 if (!image) {
		formIsValid = false;
		$('.errorfile').html('<span class="errorspan">Please select the icon</span>');
			}else{
				$('.errorfile').html('');
			}
			if (image) {		
	if (!image.name.match(/\.(jpg|jpeg|png|svg)$/)) {
		formIsValid = false;
			 $('.errorfile').html('<span class="errorspan">Please select valid type</span>');
		   
	  }else{
		$('.errorfile').html('');
	  }
	} 
	if (!selectedtypeValue) {
		formIsValid = false;
		$('.errorcontenttype').html('<span class="errorspan">Please fill the field</span>');
	}else if(selectedtypeValue){
		$('.errorcontenttype').html('');
	}
	

	this.setState({
		errors: errors
	});
	return formIsValid;
}

handleClickSelecetCategory = (event) => {
    const selectedValue = event.target.value; // Get the selected value from the event
    this.setState({ selectedCategoryValue: selectedValue }); // Set the selected value to the component's state
  }
  render() {

	const {selectedOption,selectedtype ,showCategoryDropdown} = this.state;
	var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	const navigate = this.props.navigate;
	let imageslist = [];

	  let indexlength =  imageslist.length;

	const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
		</li>
	))
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="tabmanagement" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="content-wrapper">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div>
			<div className="title">
				<h4>Add Tab</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Tab Name<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="tab_name" onChange={this.handleInputChange} className="form-control" value={this.state.tab_name}/>
					<div className="errortab_name"></div>
				</div>
				
				<div className="form-group">
						<label>Short Description<span class="required" style={{ color: "red" }} >* </span> :</label>
						<MyEditor  onEditorChange={this.onEditorChange}></MyEditor>
						<div className="errorshort_description"></div>	
				</div>
				
				
			   <div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">
										{/*imageslist*/}
									{gallerylocal}
									</ul>
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					
				</div> 
				<div className="form-group">
					<label>Button Text<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="button_name" onChange={this.handleInputChange} className="form-control" value={this.state.button_name}/>
					<div className="errorbutton_name"></div>
				</div>
				<div className="form-group">
					<label>Tab Description<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="tabdescriptions_name" onChange={this.handleInputChange} className="form-control" value={this.state.tabdescriptions_name}/>
					<div className="errortabdescriptions_name"></div>
				</div>
			</div>
                  
			<div className="form-right">
				<div className="form-group">
					<label>Icon (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} >* </span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorfile"></div>  
				</div> 
					<div className="form-group">
						<label>Description<span class="required" style={{ color: "red" }} >*</span>:</label>
						{/* <MyEditor  onEditorChange={this.onEditorChange}></MyEditor> */}
						<CKEditor
							editor={ ClassicEditor }
							data=""
							onReady={ editor => {
								// You can store the "editor" and use when it is needed.
							} }
							onChange={ ( event, editor ) => {
								const data = editor.getData();
								this.setState({description: data});
								
							} }
						/>
						<div className="errordescription"></div>  
						</div>
				<div className="default-font">
					<div className="form-group">				
						<label>Content Type<span class="required" style={{ color: "red" }} >*</span></label>
							<Select 
							value={selectedtype}
								options={lang.common.contentType_option} 
								onChange={this.handleTypeChange}
								/>
								<div className="errorcontenttype"></div>
					</div>
				</div>

				{selectedtype.value === 'form' && (
						<div className="form-group">
						<label>Agree Description<span class="required" style={{ color: "red" }} ></span></label>
						{/* <MyEditor  onEditorChange={this.onEditorChange}></MyEditor> */}
						<CKEditor
							editor={ ClassicEditor }
							data={this.state.agreedescription}
							onReady={ editor => {
								// You can store the "editor" and use when it is needed.
								console.log( 'Editor is ready to use!', editor );
							} }
							onChange={ ( event, editor ) => {
								const data = editor.getData();
								this.setState({agreedescription: data});
								
							} }
						/>
						<div className="errorshort_description"></div>  
						
					</div>
				)}
					
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >* </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />
	           </div> 
				{/* <div className="form-group">					
					<label>Content:</label>
						<textarea type="text" name='content' onChange={this.handleInputChange} className='form-control' value={this.state.content} />
					<div className="errorcontent"></div>
				</div> */}

				
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));