import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap"; 

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      categorylist:'',
      activePage:1,
      sno_index:0,
      selectedStatus:'',
      selectid:'',
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      this.listloadedData()      
   }

   listloadedData(postobject='') {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");

      if(postobject!=''){
        var postobject = postobject;
      }else{
        var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,
        loggedas: loggedas
        };  
      }

      axios.post(apiUrl+"FaqCategory/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){         
            this.setState({  categorylist: res.data.categorylist, totalRecord: res.data.records_count}, function() {
            console.log('afa');
              this.categorylist();
            });  
        }else{         
          this.setState({  categorylist: '', totalRecord: 0}, function() {            
            this.categorylist();
          });
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});
     var sno_index = pageNumber - 1;
     this.setState({sno_index: sno_index});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getcategorylist(qs.stringify(postobject))
    this.listloadedData(postobject)

   }

  handleInputChange(event) {
    const {name, value} = event.target; 
    var postObject = {             
      activePage   : this.state.activePage,
      search_all : value,
      admin_id: localStorage.getItem('admin_id')           
    };
    this.setState({
      [name]: value
    },function(){
      this.handleFormSubmit(postObject)
    });
  }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  this.listloadedData(postObject)
} 

activecategory(){
  let stat = ''
  if(this.state.selectedStatus ==='inactive'){
      stat = "I";
    }else{
      stat = "A";
    }
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
          status: stat
        };

        if(stat == "I"){
          axios.post(apiUrl+"FaqCategory/checkBeforeChange",qs.stringify(postObject)).then(res => {
            if(res.data.status === 'success'){
              this.change_status(postObject);
            }else{
              $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
              setTimeout(
              function() {
                $('.success_message').html('');
              }
              .bind(this),
              3000
              );
            }
          });
        }else{
          this.change_status(postObject);
        }

      // this.props.getactivecategory(qs.stringify(postObject));
      
}

confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activecategory();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}

change_status(postObject){
  var qs = require('qs');
  axios.post(apiUrl+"FaqCategory/change_status",qs.stringify(postObject)).then(res1 => {
    if(res1.data.status === 'success'){
      this.listloadedData()
     $('.success_message').html('<div class="status_sucess"><h3>'+ res1.data.message +'</h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
    }
  }); 
}


categorylist() {
    var categorylist = this.state.categorylist;
    var sno_index = this.state.sno_index;
    if (categorylist != "undefined" && categorylist != null) {
      console.log(categorylist);
    
      if (categorylist.length > 0) {

        if (Object.keys(categorylist).length > 0) {
          const helperlistDetails = categorylist.map(
            (categorylist, Index) => {
              if(sno_index > 0){ Index = (sno_index * 10) + Index; } 
              let sno = Index+1;
              

               if(categorylist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }
             
              return (
                <tr key={categorylist.id}>
      					<td>{sno}</td>
      					<td>{categorylist.cate_name}</td>
                
                <td>{status}</td>
                <td className="actiongroup" >
                    
                    
                     {/* <Link to={{ pathname: '/faq-view/'+categorylist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link> */}
                    <Link to={{ pathname: '/faqcategory-edit/'+categorylist.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                    {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, categorylist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}      

                   

                     {/* <Link onClick={this.deletecategory.bind(this,categorylist.id)} className="" title="Duplicate"><i className="ft-trash-2" aria-hidden="true"></i></Link> */}
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} FAQ Category?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="FaqCategory" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Faq Category</h3>
                </div>
               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                 <div className="add_button">  
                    <a className="" href='/faqcategory-add'>Add</a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Title</th>
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.categorylist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default List;