import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";


/* import { GET_VIEWUSERLIST } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const tabId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      providerid: tabId,
      userlistview:'',
      familtlistview:'',
      providerDetails:[],
      licenseStatus:'',
    };

    var qs = require('qs');
		var catid = this.state.tabId;
		var postObject = {
			provider_id : this.state.providerid,
			admin_id : localStorage.getItem("admin_id"),
		};
		axios.post(apiUrl+'provider/listdetail',qs.stringify(postObject))
		.then(res => {
			
				
			this.setState({providerDetails:res.data.providerDetails});
			
			const formdata = res.data.specialties;
			const insurance = res.data.insurance;
			const amenities = res.data.amenities;
			
		
					
					this.setState({selectedSpecialtiesOption:formdata });
					this.setState({selectedInsuranceOption:insurance });
					this.setState({selectedcategoryvalue:formdata.amenityids});
			        this.setState({selectedAmenityOption:amenities});
					this.setState({galleries:res.data.galleries});
                    this.setState({licenseStatus:res.data.providerDetails.licenseStatus});
                    
					// for (var i = 0; i < insurance.length; i++) {
					// 	// Access the 'value' property of each object and push it to the extractedValues array
					// 	extractedValues.push(specialtiesArray[i].value);
					// }
					// console.log(extractedValues,'excpected');
					// this.setState({selectedInsurcanceId:insurance.value });
					// this.setState({selectedSpecialtiesId:formdata.value });
			
		}); 
  }

   
   componentDidMount() {
     document.title = PageTitle('User View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
   
    
   }


   providerlist() {
    var qs = require('qs');
		var catid = this.state.tabId;
		var postObject = {
			provider_id : this.state.providerid,
			admin_id : localStorage.getItem("admin_id"),
		};
		axios.post(apiUrl+'provider/listdetail',qs.stringify(postObject))
		.then(res => {
			
				
			this.setState({providerDetails:res.data.providerDetails});
			
			const formdata = res.data.specialties;
			const insurance = res.data.insurance;
			const amenities = res.data.amenities;
			
		
					
					this.setState({selectedSpecialtiesOption:formdata });
					this.setState({selectedInsuranceOption:insurance });
					this.setState({selectedcategoryvalue:formdata.amenityids});
			        this.setState({selectedAmenityOption:amenities});
					this.setState({galleries:res.data.galleries});
                    this.setState({licenseStatus:res.data.providerDetails.licenseStatus});
                    
					// for (var i = 0; i < insurance.length; i++) {
					// 	// Access the 'value' property of each object and push it to the extractedValues array
					// 	extractedValues.push(specialtiesArray[i].value);
					// }
					// console.log(extractedValues,'excpected');
					// this.setState({selectedInsurcanceId:insurance.value });
					// this.setState({selectedSpecialtiesId:formdata.value });
			
		}); 
  }
   handleVerifyLicense = () => {
    let admin_id = localStorage.getItem("admin_id")
    var qs = require('qs');
    var postobject = {
        activePage: 1,
        licenseStatus:'Verified',
        admin_id: admin_id,
        provider_id : this.state.providerid,
    };  
     
    axios.post(apiUrl+"provider/verifyLicense_status",qs.stringify(postobject)).then(res => {
        console.log(res.data,'data')
        if(res.data.status === 'success'){	
          this.setState({  providerlist: res.data.providerlist, totalRecord: res.data.records_count ,data_status: res.data.status}, function() {
             this.providerlist();
            });
        } else {
           this.setState({  bt_data:[] ,providerlist: [],}, function() {
            this.providerlist();
         });
        }
      }); 
    // Update license status to "Verified"
    this.setState({
      providerDetails: {
        ...this.state.providerDetails,
        licenseStatus: 'Verified'
      }
    });
  };
  render() {
    
    const navigate = this.props.navigate;
    var mobileno = ((this.state.userlistview.mobile === null)||((this.state.userlistview.mobile === ''))) ?  this.state.userlistview.mobile = 'N/A' : this.state.userlistview.mobile;

    var lastname = ((this.state.userlistview.lastname === null)||(this.state.userlistview.lastname === "")) ?  this.state.userlistview.lastname = 'N/A' : this.state.userlistview.lastname;

   
    if(this.state.providerDetails.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="provider" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="content-wrapper">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                <div className="title">
                  <h3>Provider Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Provider Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.providerDetails.username}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.providerDetails.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Contact<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.providerDetails.phone}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Location:<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.providerDetails.location}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Rating<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.providerDetails.rating}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Reviews<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.providerDetails.reviews}</p>
                          </div>
                        </div>
                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                        </div>
                        <div className="view-details">
                            <div className="view-details-one">
                                <p><b>License Status<span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                <p>{this.state.licenseStatus?this.state.licenseStatus:'N/A'}</p>
                                {this.state.licenseStatus === 'Unverified' && (
                                    <button onClick={this.handleVerifyLicense}>Verify License</button>
                                )}
                            </div>
                        </div>
                      
                    </div>
                </div>
                    
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);
